export type FormStateType = {
  title: string,
  firstName: string,
  secondName: string,
  emailAddress: string,
  birthDay: number,
  birthMonth: number,
  birthYear: number,
  dateOfBirth: string,
  mobileNumber: number,
  postcode: string,
  address: {
    firstLine: string,
    secondLine: string,
    town: string,
    county: string,
    country: string
  },
  username: string,
  depositLimitAmount: string,
  depositLimitFrequency: string,
  plusCardNumber: number,
  pinNumber: string,
  termsAndConditions: boolean,
  termsAndConditionsPresented: boolean,
  marketingPreferences: string,
  socialMediaMarketing: string,
  onlineQuestion: string,
  addressQuestion: string,
  customerIdQuestion: string,
  plusCardNew: boolean
};

export const initialForm: FormStateType = {
  title: '',
  firstName: '',
  secondName: '',
  emailAddress: '',
  birthDay: 0,
  birthMonth: 0,
  birthYear: 0,
  dateOfBirth: '',
  mobileNumber: 0,
  postcode: '',
  address: {
    firstLine: '',
    secondLine: '',
    town: '',
    county: '',
    country: '',
  },
  username: '',
  depositLimitAmount: '',
  depositLimitFrequency: '',
  plusCardNumber: 0,
  pinNumber: '0',
  termsAndConditions: false,
  termsAndConditionsPresented: false,
  marketingPreferences: '',
  socialMediaMarketing: '1',
  onlineQuestion: '',
  addressQuestion: '',
  customerIdQuestion: '',
  plusCardNew: false
};

export const initialAddress = {
  firstLine: '',
  secondLine: '',
  town: '',
  county: '',
  country: '',
};

export interface BasePedComponentState {
  pedActive: boolean,
  pedDisabled?: boolean
}
