export const formValidationRules = {
  title: [
    {
      type: 'selected',
      value: '',
      message: 'Title is required',
    },
  ],
  firstName: [
    {
      type: 'minChars',
      value: 2,
      message: 'Name is required and must be 2 characters or more',
    },
  ],
  secondName: [
    {
      type: 'minChars',
      value: 2,
      message: 'Name is required and must be 2 characters or more',
    },
  ],
  emailAddress: [
    {
      type: 'regex',
      value: '..*?@..*?\\...*',
      message: 'Please enter a valid email address',
    },
  ],
  mobileNumber : [
    {
      type: 'regex',
      value: '^(0{0,1}7[\\d]{9})$',
      message: 'Must supply a valid UK mobile phone number',
    },
  ],
  username : [
    {
      type: 'minChars',
      value: 6,
      message: 'Username is required and must be between 6 and 15 characters long',
    },
    {
      type: 'maxChars',
      value: 15,
      message: 'Username is required and must be between 6 and 15 characters long',
    },
    {
      type: 'regex',
      value: '^[a-zA-Z0-9_]*$',
      message: 'Username is required and must contain only letters, numbers and underscores',
    },
  ],
  plusCardNumber : [
    {
      type: 'exactChars',
      value: 16,
      message: 'PLUS card number is required and must be 16 digits long (no spaces)',
    },
    {
      type: 'regex',
      value: '\\d{16}',
      message: 'PLUS card number must only consist of numbers (no spaces)',
    },
    {
      type: 'regex',
      value: '0109\\d{12}',
      message: 'PLUS card number must begin with 0109',
    },
  ],
  pinNumber : [
    {
      type: 'exactChars',
      value: 4,
      message: 'PIN is required',
    },
    {
      type: 'regex',
      value: '\\d{4}',
      message: 'PIN must only consist of numbers',
    }
  ],
  depositLimitFrequency : [
    {
      type: 'selected',
      value: '',
      message: 'Deposit limit frequency period is required',
    },
  ],
  depositLimitAmount : [
    {
      type: 'selected',
      value: '',
      message: 'Deposit limit is required',
    },
    {
      type: 'depositLimitInvalid',
      value: 'notUsed',
      message: 'Invalid deposit limit set',
    },
    {
      type: 'greaterThan',
      value: 9,
      message: 'Invalid deposit limit set',
    },
    {
      type: 'lessThan',
      value: 20001,
      message: 'Invalid deposit limit set',
    }
  ],
  birthDay : [
    {
      type: 'greaterThan',
      value: 0,
      message: 'Date of birth (DD/MM/YYYY) is required',
    },
    {
      type: 'lessThan',
      value: 32,
      message: 'Day exceeds 31',
    },
    {
      type: 'regex',
      value: '\\d{1}',
      message: 'Date of birth (DD/MM/YYYY) must consist only of numbers',
    },
  ],
  birthMonth : [
    {
      type: 'greaterThan',
      value: 0,
      message: 'Date of birth (DD/MM/YYYY) is required',
    },
    {
      type: 'lessThan',
      value: 13,
      message: 'Month exceeds 12',
    },
    {
      type: 'regex',
      value: '\\d{1}',
      message: 'Date of birth (DD/MM/YYYY) must consist only of numbers',
    },
  ],
  birthYear : [
    {
      type: 'greaterThan',
      value: 0,
      message: 'Date of birth (DD/MM/YYYY) is required',
    },
    {
      type: 'greaterThan',
      value: 1899,
      message: 'Year must be after 1900',
    },
    {
      type: 'regex',
      value: '\\d{4}',
      message: 'Date of birth (DD/MM/YYYY) must consist only of numbers',
    },
  ],
  dateOfBirth : [
    {
      type: 'dob',
      value: '',
      message: 'Customer must be over 18',
    }
  ],
  postcode : [
    {
      type: 'selected',
      value: '',
      message: 'Postcode is required',
    },
  ],
  firstLine : [
    {
      type: 'selected',
      value: '',
      message: 'House number/name is required',
    },
  ],
  town : [
    {
      type: 'selected',
      value: '',
      message: 'Town/city is required',
    },
  ],
  country : [
    {
      type: 'selected',
      value: '',
      message: 'Country is required',
    },
  ],
  termsAndConditions : [
    {
      type: 'selected',
      value: false,
      message: 'Customer agreement to terms and conditions is required',
    },
  ],
  termsAndConditionsPresented : [
    {
      type: 'selected',
      value: false,
      message: 'Confirmation that the customer has been offered the opportunity to read the Ts & Cs is required',
    },
  ],
  marketingPreferences : [
    {
      type: 'selected',
      value: '',
      message: 'Marketing preferences are required',
    },
  ]
};
