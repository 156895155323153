import React, { Component } from 'react';
import './index.css';
import '../Saved/index.css';
import checkGreen from '../../../assets/icons/check-green.svg';
import SectionHeader from '../SectionHeader';
import { FormStateType } from '../../../Form';
import ErrorMessage from '../ErrorMessage';

type SuccessProps = {
  form: FormStateType,
  failureReason: string,
  openBetAccount: string
}

class Success extends Component<SuccessProps> {
  render() {
    let message;
    switch (this.props.failureReason) {
      case 'plus':
        message = 'There was a technical issue when creating their PLUS account. They can still sign-up to PLUS separately and link to their online account later';
        break;
      case 'link':
        message = 'There was a technical issue when linking their PLUS account. They can still link to their online account later via plus.williamhill.com';
        break;
      default:
        message = '';
        break;
    }
    return (
      <div className='Success' data-testid='success'>
        <div className='success-header' id='success-header'>
          <img src={checkGreen} id='success-header-icon' />
          <div className='success-header-text'>
            <div className='saved-name'><b>Online Account created</b> for {this.props.form.firstName} {this.props.form.secondName}.</div>
            <div className='saved-email'>Username: {this.props.form.username} &nbsp; &nbsp; &nbsp; Email:  {this.props.form.emailAddress} &nbsp; &nbsp; &nbsp; {this.props.openBetAccount !== '' && <span>Account No: {this.props.openBetAccount}</span>}</div>
          </div>
        </div>
        {message !== '' && <ErrorMessage message={message} padding />}
        <div className='success-main'>
          <SectionHeader title='Next steps' id='success-header' />
          <div className='success-main-info'>
            <div className='success-main-info-text'>
              <p>
                <b>In the next 15-30 minutes:</b>
                <br />They'll receive an email containing a link for them to follow, so that they can set their password.
              </p>
              <p>
                <b>After online account set-up:</b>
                <br />When they first log into their account, they'll be asked to set a security question and answer, for added protection to their account.
              </p>
              {this.props.failureReason == 'link' && 
                <p>
                  <b>PLUS Account:</b>
                  <br />Even though you couldn't successfully link up their PLUS account, they can link their PLUS card to their online account at any time by logging in to plus.williamhill.com and selecting the 'Link' button on the homepage.
                </p>
              }
              {this.props.failureReason == 'plus' && 
                <p>
                  <b>PLUS Account:</b>
                  <br />Even though you couldn't successfully create their PLUS account, you can still create a PLUS card for the customer on the Smart Hub via the usual PLUS sign-up process. They can link their PLUS card to their online account at any time by logging in to plus.williamhill.com and selecting the 'Link' button on the homepage.
                </p>
              }
            </div>
          </div>
          <div className='success-main-info-text'>
            <b>Sign-up offer</b>
            <p>The customer has qualified for the sign-up offer where they'll receive £60 in free bets, which will be available after they place a £10 bet with their new online account. They'll find the full terms and details of this offer in the welcome email we'll send to them.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Success;
