import React, { Component } from 'react';
import './index.css';

type CheckBoxState = {
  checked: boolean
}

type CheckBoxProps = {
  text?: string,
  checked?: boolean,
  setChecked?: any,
  enablePed?: any,
  disablePed?: any,
  classNames?: string,
  disabled?: boolean,
  customerValidation?: any,
  updateTermsAndConditionsChecked?: any,
  id?: string,
  testId?: string
}

class CheckBox extends Component<CheckBoxProps, CheckBoxState> {

  onClick() {
    if (!this.props.disabled) {
      this.props.setChecked && this.props.setChecked(!this.props.checked);
      //NB: the checked property is being set in the previous line, so the following logic looks the wrong way round
      //but the value is just lagging as react isn't aware it has actually been toggled yet.
      this.props.enablePed && !this.props.checked && this.props.enablePed();
      this.props.disablePed && this.props.checked && this.props.disablePed();
      this.props.updateTermsAndConditionsChecked && this.props.updateTermsAndConditionsChecked();
      this.props.customerValidation && this.props.customerValidation();
      this.props.updateTermsAndConditionsChecked && this.props.updateTermsAndConditionsChecked();
      this.props.customerValidation && this.props.customerValidation();
      this.props.updateTermsAndConditionsChecked && this.props.updateTermsAndConditionsChecked();
    }
  }

  render() {
    return (
      <div className='check-box-container'>
        <div
          className={`CheckBox ${this.props.checked && 'checked'} ${this.props.classNames}`}
          data-testid={`${this.props.testId}-check-box`}
          id={this.props.id}
          onClick={this.onClick.bind(this)}/>
        <div>{this.props.text}</div>
      </div>
    );
  }
}

export default CheckBox;