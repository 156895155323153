import React from 'react';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import SectionHeader from '../SectionHeader';
import PedToggle from '../PedToggle';
import Divider from '../Divider';
import CheckBox from '../CheckBox';
import CustomerMessage from '../CustomerMessage';
import { MESSAGE_IDS } from '../../../MqttConstants';
import { RootState } from '../../../utils/store';
import { useDispatch, useSelector } from 'react-redux';
import { changeTermsAndConditions } from '../../../utils/store/pedSwitched';
import { changeTermsAndConditionsDisabled } from '../../../utils/store/pedDisabled';
import checkInputErrorMessages from '../checkInputErrorMessages';
import InputErrorMessage from '../Input/InputErrorMessage';

type TermsAndConditionsProps = {
  termsAndConditions: boolean,
  inputErrorMessages: object,
  termsAndConditionsPresented: boolean,
  updateForm: any,
  checkInputValue: any
}

export default function TermsAndConditions(props: TermsAndConditionsProps) {
  const pedSwitched: boolean = useSelector((state: RootState) => state.pedSwitched.termsAndConditions);
  const pedDisabled: boolean = useSelector((state: RootState) => state.pedDisabled.termsAndConditions);
  const dispatch = useDispatch();
  const checked = props.termsAndConditionsPresented;

  function togglePed(switched: boolean): void{
    dispatch(changeTermsAndConditions(!switched));
  }

  function enablePed() {
    dispatch(changeTermsAndConditionsDisabled(false));
  }

  function disablePed() {
    dispatch(changeTermsAndConditions(false));
    dispatch(changeTermsAndConditionsDisabled(true));
  }

  return (
    <div
      className={`registration-form-section ${pedSwitched && 'ped-active'}`}
      data-testid='terms-and-conditions' id='terms-and-conditions'>
      <SectionHeader title='Terms & Conditions' id='terms-and-conditions'/>
      <PedToggle
        pedDisabled={pedDisabled} pedActive={pedSwitched}
        togglePed={() => togglePed(pedSwitched)} type={MESSAGE_IDS.termsAndConditions}/>
      <Divider/>
      <div className='registration-form-body'>
        <div className='terms-and-conditions-container'>
          <CustomerMessage title='Customer Message' id='customer-message'>
            We will process your personal data in accordance with our Online Privacy Notice which you can read, along with our Online Account Terms & Conditions, Age & Verification Policy and Plus Card Terms & Conditions, on screen now.
            <p>Please press (1) on the NFC Reader to confirm that you wish to register for an Online Account and a Plus Card, and you agree to these terms and conditions.</p>
            <p>Please note that, in order to comply with our legal obligation to meet UK Gambling Commission guidance on affordability checks, we use TransUnion to provide verification services which involves sharing some of your personal data with TransUnion. Our affordability checks will not negatively impact your credit score.</p>
          </CustomerMessage>
          <div>
            <CheckBox
              text='Confirm that the customer has been offered the opportunity to read the Ts & Cs'
              checked={checked}
              disabled={checked && props.termsAndConditions}
              updateTermsAndConditionsChecked={async () => {
                await props.updateForm('termsAndConditionsPresented', !checked);
                props.checkInputValue('termsAndConditionsPresented');
              }}
              classNames={checkInputErrorMessages(['termsAndConditionsPresented'], props.inputErrorMessages)[0] !== '' ? 'error' : ''}
              disablePed={disablePed}
              enablePed={enablePed} />
            <InputErrorMessage id='terms-and-conditions-presented' message={checkInputErrorMessages(['termsAndConditionsPresented'], props.inputErrorMessages)[1]} />
          </div>
          <div className='terms-and-conditions-customer-check-box' id='termsAndConditions-label'>
            <CheckBox
              checked={props.termsAndConditions}
              text='Customer to confirm they agree to the Ts & Cs (1)'
              classNames={checkInputErrorMessages(['termsAndConditions'], props.inputErrorMessages)[0] !== '' ? 'error' : ''}
            />
            <InputErrorMessage id='terms-and-conditions' message={checkInputErrorMessages(['termsAndConditions'], props.inputErrorMessages)[1]} />
          </div>
        </div>
      </div>
    </div>
  );
}