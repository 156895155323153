import { MessageTypes } from './MqttConstants';
import { MqttClient } from 'mqtt';

export interface CreateAccountRequest {
  data: Data;
  validatorVersion: string;
}

export interface Data {
  title: string,
  username: string,
  firstName: string,
  lastName: string,
  dob: string,
  email: string,
  mobile: string,
  street1: string,
  street2: string,
  postcode: string,
  city: string,
  promoCode: string,
  language: string,
  contactable: string,
  socialable: string,
  county: string,
  countryCode: string,
  currencyCode: string,
  depositLimit: string,
  depositLimitPeriod: string
}

export interface LinkOnlineAccountWithPlusAccountRequest {
  openBetAccount: string;
  consents: ConsentsUpdate;
}

export interface ConsentsUpdate {
  marketing: boolean;
}

export interface ApiResponse {
  status: number;
  data: any;
}

export const responseWithBody = (body: any, status: number): ApiResponse => ({
  status: status,
  data: body,
});

export interface PublishMessageData {
  metadata: Metadata;
  body: PublishBody;
}

export interface SubscribeMessageData {
  metadata: Metadata;
  body: SubscribeBody;
}

export interface Metadata {
  message: Message;
}

export interface Message {
  id: string;
  schemaVersion: string;
  type: MessageTypes;
}

export interface PublishBody {
  displayMessage: string;
  beep: number,
  readKeypadDigits: number,
  mask: boolean
}

export interface SubscribeBody {
  success: string;
  data: string;
  requestId: string;
}

export interface MqttError {
  name: string;
  message: string;
  stack?: string;
}

export interface IMqttContext {
  connectionStatus: string | MqttError;
  client?: MqttClient | null;
  parserMethod?: (message: any) => string;
}

export interface IMessageStructure {
  [key: string]: string;
}

export interface IMessage {
  topic: string;
  message?: string | IMessageStructure;
}

export interface IUseSubscription {
  topic: string | string[];
  client?: MqttClient | null;
  message?: IMessage;
  connectionStatus: string | MqttError;
}
