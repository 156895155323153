import App from './App';
import MqttProvider from './providers/MqttProvider';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './utils/store';

import './index.css';
import { logger } from './lib/logger';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const MqttWrappedApp = () => {
  logger.log(`process.env: ${JSON.stringify(process.env, null, 2)}`);

  window.addEventListener('message', event => {
    if (event.origin === 'http://localhost:8080') {
      document.getElementById('top-navigation-button')?.click();
    }
  });

  return (
    <MqttProvider brokerUrl='ws://mosquitto:8883/' options={{ keepalive: 60 }}>
      <Provider store={store}>
        <App />
      </Provider>
    </MqttProvider>
  );
};

root.render(<MqttWrappedApp />);