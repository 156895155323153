import React from 'react';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import SectionHeader from '../SectionHeader';
import PedToggle from '../PedToggle';
import Divider from '../Divider';
import CheckBox from '../CheckBox';
import InfoButtonTooltip from '../InfoButtonTooltip';
import Button from '../Button';
import CustomerMessage from '../CustomerMessage';
import TOPICS, { DISPLAY_MESSAGES, MESSAGE_IDS, MessageTypes } from '../../../MqttConstants';
import EditSelectionLink from '../EditSelectionLink';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/store';
import { changeMarketing } from '../../../utils/store/pedSwitched';
import checkInputErrorMessages from '../checkInputErrorMessages';
import InputErrorMessage from '../Input/InputErrorMessage';
import resetPed from '../../../utils/resetPed';
import { publishMQTTMessage, useMqttState } from '../../../utils/MqttUtils';

type MarketingProps = {
  checkAllFields: any,
  accepted?: boolean,
  marketingPreferences: string,
  socialMediaMarketing: string,
  inputErrorMessages: object
}

export default function Marketing(props: MarketingProps) {
  const pedSwitched: boolean = useSelector((state: RootState) => state.pedSwitched.marketing);
  const pedDisabled: boolean = useSelector((state: RootState) => state.pedDisabled.marketing);
  const dispatch = useDispatch();
  const { client } = useMqttState();

  function resetOrDisplayDefaultMessage(): void {
    resetPed();
    publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.defaultMessage, '', 0, MessageTypes.NFC_MESSAGE);
  }

  function togglePed(switched: boolean): void {
    dispatch(changeMarketing(!switched));
  }

  return (
    <div className={`registration-form-section ${pedSwitched && 'ped-active'}`} data-testid='marketing' id='marketing'>
      <SectionHeader title='Marketing' id='terms-and-conditions'/>
      <PedToggle
        pedActive={pedSwitched} togglePed={() => togglePed(pedSwitched)}
        type={MESSAGE_IDS.marketingPreferences} pedDisabled={pedDisabled}/>
      <Divider/>
      <div className='registration-form-body'>
        <div className='marketing-container'>
          <CustomerMessage title='Customer Message' id='customer-message' classNames='margin-bottom'>
            Press 1 on the NFC Reader to opt in to William Hill marketing or press 2 to opt out.
          </CustomerMessage>
          <div className='marketing-customer-check-box' id='marketingPreferences-label'>
            In signing-up you will receive William Hill marketing
            <div>
              <div className='marketing-check-boxes'>
                <CheckBox text='Opt in (1)' checked={props.marketingPreferences == '1'} classNames={checkInputErrorMessages(['marketingPreferences'], props.inputErrorMessages)[0] !== '' ? 'error' : ''} />
                <CheckBox text='Opt out (2)' checked={props.marketingPreferences == '2'} classNames={checkInputErrorMessages(['marketingPreferences'], props.inputErrorMessages)[0] !== '' ? 'error' : ''} />
                {pedDisabled && <EditSelectionLink
                  topic={TOPICS.nfc_action} displayMessage={DISPLAY_MESSAGES.marketingPreferences}
                  messageId={MESSAGE_IDS.marketingPreferences} keypadDigits={1}
                  type={MessageTypes.NFC_ACTION}/>}
              </div>
              <InputErrorMessage id='marketing' message={checkInputErrorMessages(['marketingPreferences'], props.inputErrorMessages)[1]} />
            </div>
          </div>
          <CustomerMessage title='Customer Message' id='customer-message' classNames='margin-bottom'>
            In signing-up you will also opted in to receive marketing via Social Media. Press 2 if you would like to opt out.
          </CustomerMessage>
          <div className='check-box-edit-link-container'>
            <CheckBox text='Marketing via Social Media' checked={props.socialMediaMarketing == '1'} />
            <InfoButtonTooltip id='social-media-marketing-tooltip' classNames='left-align'>
              <p className='standard-tooltip'>
                Customer will see William Hill adverts on Social Media
              </p>
            </InfoButtonTooltip>
            <EditSelectionLink
              topic={TOPICS.nfc_action} displayMessage={DISPLAY_MESSAGES.socialMediaMarketing}
              messageId={MESSAGE_IDS.socialMediaMarketing} keypadDigits={1}
              type={MessageTypes.NFC_ACTION}/>
          </div>
          <InputErrorMessage id='social-media-marketing' message={checkInputErrorMessages(['socialMediaMarketing'], props.inputErrorMessages)[1]} />
        </div>
      </div>
      <Divider/>
      <div className='registration-form-body'>
        <div className='marketing-container'>
          <div className='promo-offer'>
            <b>New Customer sign-up offer: £60 in FREE BETS</b>
            <InfoButtonTooltip id='promo-offer-tooltip' classNames='left-align'>
              <p className='standard-tooltip'>
                In signing-up, the customer will qualify for our fantastic offer which gives them £60 in free
                bets!
                <br/><br/>
                <b>
                  New customers only.
                  Min £10 bet. Min odds 1/2 (1.5).
                  Free bets paid as 4 x online and 2 x PLUS card £10 free bets (expires after 30 days).
                  Eligibility rules, free-bet rules, location restrictions, payment-method restrictions and terms &
                  conditions apply.
                  Full terms of the offer can be viewed in the customer's welcome email.
                </b>
              </p>
            </InfoButtonTooltip>
          </div>
          <Button
            id='submission' text='Agree and join'
            classNames='button-margin-top button-active'
            checkAllFields={props.checkAllFields}
            resetPed={resetOrDisplayDefaultMessage}
          />
        </div>
      </div>
    </div>
  );
}