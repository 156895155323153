import { IMessage, IMqttContext, IUseSubscription, PublishMessageData } from '../types';
import { MessageTypes } from '../MqttConstants';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IClientSubscribeOptions } from 'mqtt';
import { MqttContext } from '../providers/MqttProvider';

export function publishMQTTMessage(client: any, topic: string, displayMessage: string, messageId: string, keypadDigits: number, type: MessageTypes, mask = false) {
  const messageToPublish: PublishMessageData = {
    metadata: {
      message: {
        id: messageId,
        schemaVersion: '1.0.0',
        type: type
      }
    },
    body: {
      displayMessage: displayMessage,
      beep: 2,
      readKeypadDigits: keypadDigits,
      mask: mask,
    }
  };
  client?.publish(topic, JSON.stringify(messageToPublish));
  console.log('publishing topic : {}', topic);
  console.log('publishMessageProps : {}', JSON.stringify(messageToPublish));
}

export function useMqttState() {
  const { connectionStatus, client, parserMethod } = useContext<IMqttContext>(
    MqttContext,
  );

  return {
    connectionStatus,
    client,
    parserMethod,
  };
}

export function useSubscription(
  topic: string | string[],
  options: IClientSubscribeOptions = {} as IClientSubscribeOptions,
): IUseSubscription {
  const { client, connectionStatus, parserMethod } = useContext<IMqttContext>(
    MqttContext,
  );

  const [message, setMessage] = useState<IMessage | undefined>(undefined);

  const subscribe = useCallback(async () => {
    client?.subscribe(topic, options);
  }, [client, options, topic]);

  const callback = useCallback(
    (receivedTopic: string, receivedMessage: any) => {
      if ([topic].flat().some(rTopic => matches(rTopic, receivedTopic))) {
        setMessage({
          topic: receivedTopic,
          message:
            parserMethod?.(receivedMessage) || receivedMessage.toString(),
        });
      }
    },
    [parserMethod, topic],
  );

  useEffect(() => {
    if (client?.connected) {
      subscribe();

      client.on('message', callback);
    }
    return () => {
      client?.off('message', callback);
    };
  }, [callback, client, subscribe]);

  return {
    client,
    topic,
    message,
    connectionStatus,
  };
}

function matches(pattern: string, topic: string): boolean {
  const patternSegments = pattern.split('/');
  const topicSegments = topic.split('/');

  if (patternSegments.length > topicSegments.length) return false;

  for (let i = 0; i < patternSegments.length; i++) {
    const currentPatternSegment = patternSegments[i];
    const currentTopicSegment = topicSegments[i] || '';
    const firstCharOfPatternSegment = currentPatternSegment[0];

    if (firstCharOfPatternSegment === '#') {
      return i === patternSegments.length - 1;
    } else if (firstCharOfPatternSegment !== '+' && currentPatternSegment !== currentTopicSegment) {
      return false;
    }
  }

  return patternSegments.length === topicSegments.length;
}
