import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Input from '../../Input';
import InputErrorMessage from '../../Input/InputErrorMessage';
import Label from '../../Label';
import './index.css';
import checkInputErrorMessages from '../../checkInputErrorMessages';
import axios from 'axios';
import Button from '../../Button';
import { addressFields } from './addressFields';
import { constants } from '../../../../utils/constants';
import InfoButtonTooltip from '../../InfoButtonTooltip';
import { logger } from '../../../../lib/logger';

const apiKey = 'YN42-ND21-NR91-DH45';

const findUrl = `${constants.get('REACT_APP_FIND_POSTCODE_BASE_URL')}?Key=${apiKey}`;
const retrieveUrl = `${constants.get('REACT_APP_RETRIEVE_ADDRESS_BASE_URL')}?Key=${apiKey}`;

type PostcodeLookupState = {
  open: boolean,
  postcodeOptions: object,
  addressFound: boolean,
  addressOpen: boolean,
}

type PostcodeLookupProps = {
  updateForm?: any,
  inputErrorMessages: object,
  checkInputValue: any,
  postcode: string,
  address: object,
}

class PostcodeLookup extends Component<PostcodeLookupProps, PostcodeLookupState> {

  state = {
    open: false,
    postcodeOptions: [],
    addressFound: false,
    addressOpen: false,
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.postcode !== this.props.postcode) {
      !this.state.addressFound && this.props.postcode.length > 2 && this.lookupPostcode(this.props.postcode);
      if (this.props.postcode.length < 3) {
        this.updatePostcodeOptions([]);
        this.updateOpen(false);
      }
      this.updateAddressFound(false);
    } else if (prevState.addressFound !== this.state.addressFound) {
      for (let index = 0; index < addressFields.length; index++) {
        this.props.checkInputValue(addressFields[index].ref);
      }
    }
  }

  updatePostcodeOptions(options: object) {
    this.setState(prevState => {
      const { open, addressFound, addressOpen } = prevState;
      return {
        open,
        postcodeOptions: options,
        addressFound,
        addressOpen,
      };
    });
  }

  updateOpen(value: boolean) {
    this.setState(prevState => {
      const { postcodeOptions, addressFound, addressOpen } = prevState;
      return {
        open: value,
        postcodeOptions,
        addressFound,
        addressOpen,
      };
    });
  }

  updateAddressFound(value: boolean) {
    this.setState(prevState => {
      const { open, postcodeOptions, addressOpen } = prevState;
      return {
        open,
        postcodeOptions,
        addressFound: value,
        addressOpen,
      };
    });
  }

  updateAddressOpen(value: boolean) {
    this.setState(prevState => {
      const { open, postcodeOptions, addressFound } = prevState;
      return {
        open,
        postcodeOptions,
        addressFound,
        addressOpen: value,
      };
    });
  }

  enterManually() {
    this.updateAddressOpen(true);
    this.updateOpen(false);
  }

  async lookupPostcode(postcode: string) {
    const data = await this.findPostcode(postcode, 'Text');
    this.updatePostcodeOptions(data.Items);
    this.updateOpen(true);
  }

  async findPostcode(value: string, type: string) {
    const url = (type == 'Id') ? retrieveUrl : findUrl;
    try {
      const { status, data } = await axios.request({
        method: 'get',
        url: `${url}&${type}=${value}`,
      });
      logger.log('Status: ' + status);
      return status == 200 && data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        logger.error('Error message: ', error.response && error.response.status);
      } else {
        logger.error('Unexpected error: ', error);
      }
    }
  }

  async handleSelection(Id: string, Type: string) {
    if (Type === 'Postcode' || Type === 'Container') {
      const data = await this.findPostcode(Id, 'Container');
      this.updatePostcodeOptions(data.Items);
    } else if (Type === 'Address') {
      const data = await this.findPostcode(Id, 'Id');
      const address = data.Items[0];
      this.updatePostcodeOptions([]);
      this.props.updateForm('firstLine', address.Line1, 'address');
      this.props.updateForm('secondLine', address.Line2, 'address');
      this.props.updateForm('town', address.City, 'address');
      this.props.updateForm('county', address.Province, 'address');
      this.props.updateForm('country', address.CountryName, 'address');
      this.updateAddressFound(true);
      this.props.updateForm('postcode', address.PostalCode);
      this.updateAddressOpen(true);
    }
  }

  handleAddressInput(event: any) {
    this.props.updateForm(event.target.name, event.target.value, 'address');
  }

  clearField(field: string) {
    this.props.updateForm(field, '', 'address');
  }

  handleClickOutside() {
    this.updateOpen(false);
  }

  render() {
    return (
      <div className='postcode-lookup' data-testid='postcode-lookup'>
        <div className='personal-details-options-row'>
          <Label label='Postcode' id='postcode' />
          <Label label='(Enter manually)' classNames='label-blue-text enter-manually-link' onClick={this.enterManually.bind(this)} testId='enter-manually' id='manual-address' />
          <InfoButtonTooltip id='postcode-tooltip' classNames='left-align'>
            <p className='standard-tooltip'>We need this information to validate the customer's identity</p>
          </InfoButtonTooltip>
        </div>
        <Input value={this.props.postcode} id='postcode-input' classNames={checkInputErrorMessages(['postcode'], this.props.inputErrorMessages)[0]} placeholder="Enter customer's postcode" name='postcode' inputType='text' updateForm={this.props.updateForm.bind(this)} checkInputValue={this.props.checkInputValue.bind(this, 'postcode')} updateOpen={this.updateOpen.bind(this)} />
        {this.state.open && this.state.postcodeOptions.length > 0 &&
          <div className='postcode-options-container' data-testid='postcode-options-container' id='postcode-options-container'>
            <div className='postcode-options' data-testid='postcode-options' id='postcode-options'>
              {this.state.postcodeOptions.map((option, i) => {
                const { Text, Description, Id, Type } = option;
                return (
                  <div key={i} className={`postcode-option ${i % 2 != 0 && 'alternate'}`} onClick={this.handleSelection.bind(this, Id, Type)} id={`postcode-option-${i.toString()}`}>
                    <div className='postcode' id={`postcode-option-${i.toString()}-text`}>{Text}</div>
                    {Description}
                  </div>
                );
              })}
            </div>
            <div className='postcode-options-footer' id='postcode-options-footer'>
              Can't find the address?
              <Button id='manual-address' enterManually={this.enterManually.bind(this)} text={'Let me type it in'} classNames={'button-active button-width'} />
            </div>
          </div>
        }
        <InputErrorMessage id='postcode' message={checkInputErrorMessages(['postcode'], this.props.inputErrorMessages)[1]} />
        {this.state.addressOpen &&
          <div className='address-container' data-testid='address-container' id='address-container'>
            {addressFields.map((field, i) => {
              return (
                <div key={i} className='address-field'>
                  <div className={`address-title ${i !== 0 && 'margin-top'}`} id={`address-field-${i.toString()}-title`}>{field.title}</div>
                  <Input id={`address-field-${i.toString()}-input`} name={field.ref} classNames={checkInputErrorMessages([field.ref], this.props.inputErrorMessages)[0]} placeholder={field.placeholder} value={this.props.address[field.ref as keyof typeof this.props.address]} handleAddressInput={this.handleAddressInput.bind(this)} checkInputValue={this.props.checkInputValue.bind(this, field.ref)} />
                  <div className={`address-clear ${i == 0 && 'top'}`} onClick={this.clearField.bind(this, field.ref)} id={`address-field-${i.toString()}-clear`}>Clear</div>
                  <InputErrorMessage id={`address-field-${i.toString()}`} message={checkInputErrorMessages([field.ref], this.props.inputErrorMessages)[1]} />
                </div>
              );
            })}
          </div>
        }
      </div>
    );
  }
}

export default onClickOutside(PostcodeLookup);
