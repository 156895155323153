import React, { Component } from 'react';
import './index.css';
import '../Success/index.css';
import '../Saved/index.css';
import SectionHeader from '../SectionHeader';
import ErrorMessage from '../ErrorMessage';

type FailedProps = {
  failureReason: string
}

class Failed extends Component<FailedProps> {
  render() {
    return (
      <div className='Failed' data-testid='failed'>
        <ErrorMessage message={`${this.props.failureReason == 'email' ? "Email address already in use. We couldn't create this customer's account. Follow the steps below to fix this." : "We couldn't create this customer's account. Follow the steps below to fix this."}`} failed />
        <div className='failed-main'>
          <SectionHeader title='Next steps' id='failed-header' />
          <div className='success-main-info'>
            <div className='success-main-info-text'>
              {this.props.failureReason == 'email' ? (
                <p>This email address is already in use so the customer will be unable to open a new online account today. Tell them to try reactivating their account by contacting Customer Support, and recovering their old username and password via Live Chat.</p>
              ) : (
                <p>
                  <b>You'll need to do one of two things to help a customer create their account:</b>
                  <p>1. Tell the customer that their registration hasn't worked, and you'll need to try again.</p>
                  <p>2. If this doesn't work or there are persistent problems, tell the customer that they can come back another time to try again - or sign themselves up online using the website or William Hill app.</p>
                  <br />
                  <p>
                    <b>PLUS Account</b>
                    <p>
                      If a customer signs-up themselves online using the website or William Hill app, you can still create a PLUS card for the customer on the Smart Hub via the usual PLUS sign-up process.
                      <p>They can link their PLUS card to their online account at any time by logging in to plus.williamhill.com and selecting the 'Link' button on the homepage.</p>
                    </p>
                  </p>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Failed;
