import React, { Component } from 'react';
import './index.css';
import PersonalDetails from './PersonalDetails';
import AccountDetails from './AccountDetails';
import PlusCard from './PlusCard';
import TermsAndConditions from './TermsAndConditions';
import ErrorMessage from './ErrorMessage';
import Success from './Success';
import { FormStateType } from '../../Form';
import callPhoneService from '../../utils/phoneService';
import callCardService from '../../utils/cardService';
import { ApiResponse } from '../../types';
import Marketing from './Marketing';
import Saved from './Saved';
import Failed from './Failed';
import CustomerValidation from './CustomerValidation';
import ResponseSubmitted from './ResponseSubmitted';

type FormProps = {
  success: boolean,
  updateSuccess: any,
  form: FormStateType,
  error: boolean,
  inputErrorMessages: object,
  updateForm: any,
  checkInputValue: any,
  checkAllFields: any,
  saved: boolean,
  retrieveData: any,
  autofillPrompt: boolean,
  autofill: any,
  usernamePrompt: boolean,
  retrievedUsernames: any,
  updateState: any,
  checkPlusCard: any,
  updateInputErrorMessages: any,
  failed: boolean,
  failureReason: string,
  responseSubmitted: boolean,
  openBetAccount: string
}

type FormState = {
  showPhonePrompt: boolean,
  plusCardNumber: string,
}

class RegistrationForm extends Component<FormProps, FormState> {

  state = {
    showPhonePrompt: false,
    plusCardNumber: '',
  };

  updateShowPhonePrompt(value: boolean) {
    this.setState( prevState => {
      const { plusCardNumber } = prevState;
      return {
        showPhonePrompt: value,
        plusCardNumber: plusCardNumber,
      };
    });
  }

  updatePlusCardNumber(value: string) {
    this.setState( prevState => {
      const { showPhonePrompt } = prevState;
      return {
        showPhonePrompt: showPhonePrompt,
        plusCardNumber: value,
      };
    });
  }

  async queryPhoneNumber() {
    const mobileNumber = this.props.form.mobileNumber.toString();
    const addPrefix = mobileNumber.length !== 11;
    const phoneResponse : ApiResponse = await callPhoneService(`phonenumber/${addPrefix ? '0' : ''}${mobileNumber}`);
    const { data } = phoneResponse;
    const cardResponse = await callCardService(`?ssbtAccount=${data.ssbtAccount}`);
    const { cardNumber } = cardResponse.data;
    if (cardNumber) {
      this.updatePlusCardNumber(cardNumber);
      this.updateShowPhonePrompt(true);
    }
  }

  confirmPlusCard() {
    this.props.updateForm('plusCardNumber', `0109${this.state.plusCardNumber}`);
  }

  render() {
    return (
      <div className='RegistrationForm' data-testid='registration-form'>
        {this.props.error && <ErrorMessage message={this.props.error} data-testid='error-message' />}
        {this.props.responseSubmitted && <ResponseSubmitted message='Response Submitted' />}
        {this.props.success ? 
          <Success form={this.props.form} data-testid='success' failureReason={this.props.failureReason} openBetAccount={this.props.openBetAccount} /> :
          this.props.failed ?
            <Failed failureReason={this.props.failureReason} /> :
            this.props.saved ?
              <Saved form={this.props.form} data-testid='saved' /> :
              <div>
                <CustomerValidation title='Customer Validation' subtitle={'Check that the customer has appropriate documentation to complete KYC. '} id={'customer-validation'} updateForm={this.props.updateForm.bind(this)} inputErrorMessages={this.props.inputErrorMessages} checkInputValue={this.props.checkInputValue.bind(this)} form={this.props.form} />
                {this.props.form.customerIdQuestion !== '' && 
                  <div>
                    <PersonalDetails queryPhoneNumber={this.queryPhoneNumber.bind(this)} autofill={this.props.autofill.bind(this)} autofillPrompt={this.props.autofillPrompt} form={this.props.form} updateForm={this.props.updateForm.bind(this)} inputErrorMessages={this.props.inputErrorMessages} checkInputValue={this.props.checkInputValue.bind(this)} data-testid='personal-details' retrieveData={this.props.retrieveData.bind(this)} />
                    <AccountDetails updateState={this.props.updateState.bind(this)} retrievedUsernames={this.props.retrievedUsernames} usernamePrompt={this.props.usernamePrompt} form={this.props.form} updateForm={this.props.updateForm.bind(this)} inputErrorMessages={this.props.inputErrorMessages} checkInputValue={this.props.checkInputValue.bind(this)} data-testid='account-details' updateInputErrorMessages={this.props.updateInputErrorMessages.bind(this)} />
                    <PlusCard updatePlusCardNumber={this.updatePlusCardNumber.bind(this)} updateShowPhonePrompt={this.updateShowPhonePrompt.bind(this)} confirmPlusCard={this.confirmPlusCard.bind(this)} showPhonePrompt={this.state.showPhonePrompt} updateInputErrorMessages={this.props.updateInputErrorMessages.bind(this)} checkPlusCard={this.props.checkPlusCard.bind(this)} form={this.props.form} updateForm={this.props.updateForm.bind(this)} inputErrorMessages={this.props.inputErrorMessages} checkInputValue={this.props.checkInputValue.bind(this)} data-testid='plus-card' />
                    <TermsAndConditions data-testid='terms-and-conditions' termsAndConditions={this.props.form.termsAndConditions} termsAndConditionsPresented={this.props.form.termsAndConditionsPresented} inputErrorMessages={this.props.inputErrorMessages} updateForm={this.props.updateForm.bind(this)} checkInputValue={this.props.checkInputValue.bind(this)} />
                    <Marketing data-testid='marketing' checkAllFields={this.props.checkAllFields.bind(this)} marketingPreferences={this.props.form.marketingPreferences} socialMediaMarketing={this.props.form.socialMediaMarketing} inputErrorMessages={this.props.inputErrorMessages} />
                  </div>
                }
              </div>
        }
      </div>
    );
  }
}

export default RegistrationForm;
