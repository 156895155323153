import React from 'react';
import axios from 'axios';

import Divider from '../Divider';
import Input from '../Input';
import Label from '../Label';
import SectionHeader from '../SectionHeader';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import ErrorMessage from '../Input/InputErrorMessage';
import checkInputErrorMessages from '../checkInputErrorMessages';
import { FormStateType } from '../../../Form';
import UsernamePrompt from './UsernamePrompt';
import { constants } from '../../../utils/constants';
import CustomerMessage from '../CustomerMessage';
import PedToggle from '../PedToggle';
import InfoButtonTooltip from '../InfoButtonTooltip';
import EditSelectionLink from '../EditSelectionLink';
import TOPICS, { DISPLAY_MESSAGES, MessageTypes, MESSAGE_IDS } from '../../../MqttConstants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/store';
import { changeDepositLimit } from '../../../utils/store/pedSwitched';
import { logger } from '../../../lib/logger';

type AccountDetailsProps = {
  updateForm?: any,
  inputErrorMessages: object,
  checkInputValue: any,
  form: FormStateType,
  usernamePrompt: boolean,
  retrievedUsernames: any,
  updateState: any,
  updateInputErrorMessages: any,
}

export default function AccountDetails(props: AccountDetailsProps) {

  const pedDisabled = useSelector((state: RootState) => state.pedDisabled.depositLimit);
  const pedSwitched = useSelector((state: RootState) => state.pedSwitched.depositLimit);
  const dispatch = useDispatch();

  async function updateDepositLimit(key: string, value: any) {
    if (key == 'amount') {
      await props.updateForm('depositLimitAmount', value);
      props.checkInputValue('depositLimitAmount');
    } else {
      await props.updateForm('depositLimitFrequency', value);
      props.checkInputValue('depositLimitFrequency');
    }
  }

  async function callUsernameService() {
    try {
      const { status, data } = await axios.request({
        method: 'post',
        url: `${constants.get('REACT_APP_USERNAME_SUGGESTION_SERVICE_BASE_URL')}/account/usernameSuggestions`,
        data: {
          username: props.form.username,
        },
      });
      if (status == 200) {
        const usernames = data.usernameSuggestions;
        if (usernames.length > 0) {
          props.updateState('retrievedUsernames', usernames);
          props.updateState('usernamePrompt', true);
        } else {
          props.updateInputErrorMessages('usernameVerified', 'Username verified');
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        logger.error('Error message: ', error.response && error.response.status);
      } else {
        logger.error('Unexpected error: ', error);
      }
    }
  }

  function checkUsernameValue() {
    props.updateInputErrorMessages('usernameVerified', false);
    props.checkInputValue('username');
    props.checkInputValue('usernameVerified');
  }

  function togglePed(value: boolean) {
    dispatch(changeDepositLimit(value));
  }

  const customerMessageInfoButton = <InfoButtonTooltip id='customerMessageInfoButton' classNames='left-align'>
    <p className='standard-tooltip'>Once registered, a customer can remove this limit by sending us some documentation to make sure they have the funds to support their gambling activity with us.
    </p>
    <table>
      <thead>
        <tr>
          <th>Customer Age</th>
          <th>Net deposit limit applied for first 30 days</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Under 25</td>
          <td>£1,000</td>
        </tr>
        <tr>
          <td>Over 25</td>
          <td>£2,000</td>
        </tr>
      </tbody>
    </table>
  </InfoButtonTooltip>;

  return (
    <div className={`registration-form-section ${pedSwitched && 'ped-active'}`} data-testid='account-details'>
      <SectionHeader title='Account details' subtitle='Complete all fields.' id='account-details' />
      <PedToggle pedActive={pedSwitched} togglePed={togglePed} type={MESSAGE_IDS.depositFrequency} updateDepositLimit={updateDepositLimit} checkInputValue={props.checkInputValue} pedDisabled={pedDisabled} />
      <Divider />
      <div className='registration-form-body'>
        <Label label='Username' id='username' />
        <Input
          id='username-input'
          placeholder="Enter customer's preferred username (e.g. PeterSmith_1978)"
          callUsernameService={callUsernameService}
          value={props.form.username}
          name='username'
          errorMessage={checkInputErrorMessages(['username'], props.inputErrorMessages)[1]}
          classNames={`${checkInputErrorMessages(['username'], props.inputErrorMessages)[0]} ${props.usernamePrompt && 'input-prompt'}`}
          updateForm={props.updateForm}
          inputType='text'
          checkInputValue={checkUsernameValue}
          updateState={props.updateState}
          inputPromptLabel='Customer should choose a username that is unique and easy to remember, and between 6 to 15 characters - letters, numbers and underscores are allowed. Capitalisation matters. No spaces allowed. '
        />
        {props.usernamePrompt && <UsernamePrompt updateState={props.updateState} updateForm={props.updateForm} retrievedUsernames={props.retrievedUsernames} updateInputErrorMessages={props.updateInputErrorMessages} />}
        <div className='tooltip-button-wrapper'>
          <Label label='Deposit limit' id='depositLimitAmount' />
          <InfoButtonTooltip id='deposit-limit-info' classNames='left-align'>
            <p className='standard-tooltip'>All accounts must have a deposit limit set (either by day, week or month). Minimum limit - £10 Maximum limit - £20,000</p>
          </InfoButtonTooltip>
        </div>
        <CustomerMessage title='Customer Message' id='customer-message' classNames='margin-top margin-bottom'>
          Use the NFC Reader to set a deposit limit on the account. <b>This limit only applies to the online account.</b>
          <br />Select to set the limit by Day (press 1), by Week (press 2) or by Month (press 3).
          <br />Hit enter to confirm and then input desired limit.
        </CustomerMessage>
        <div className='registration-form-container primary' id='depositLimitFrequency-label'>
          <button disabled className={`registration-form-buttons secondary bold ${props.form.depositLimitFrequency == 'day' && 'registration-form-buttons-active'} ${checkInputErrorMessages(['depositLimitFrequency'], props.inputErrorMessages)[0]}`} onClick={() => updateDepositLimit('frequency', 'day')} data-testid='deposit-limit-daily' id='deposit-limit-daily'>Daily<span className='ped-number'>&nbsp;(1)</span></button>
          <button disabled className={`registration-form-buttons secondary bold ${props.form.depositLimitFrequency == 'week' && 'registration-form-buttons-active'} ${checkInputErrorMessages(['depositLimitFrequency'], props.inputErrorMessages)[0]}`} onClick={() => updateDepositLimit('frequency', 'week')} data-testid='deposit-limit-weekly' id='deposit-limit-weekly'>Weekly<span className='ped-number'>&nbsp;(2)</span></button>
          <button disabled className={`registration-form-buttons secondary bold ${props.form.depositLimitFrequency == 'month' && 'registration-form-buttons-active'} ${checkInputErrorMessages(['depositLimitFrequency'], props.inputErrorMessages)[0]}`} onClick={() => updateDepositLimit('frequency', 'month')} data-testid='deposit-limit-monthly' id='deposit-limit-monthly'>Monthly<span className='ped-number'>&nbsp;(3)</span></button>
        </div>
        <ErrorMessage id='deposit-limit-frequency' message={checkInputErrorMessages(['depositLimitFrequency'], props.inputErrorMessages)[1]} />
        <div className='registration-form-container primary column margin-top' id='depositLimitAmount-label'>
          <Label label='Enter deposit limit' id='enter-limit' />
          <div className='digits-message'>NFC Reader will require customer to type in 5 digits <b>e.g £10 would be 00010</b></div>
          <div className='input-edit-wrapper'>
            <Input
              id='deposit-limit-input' placeholder=''
              required={true}
              //if any value is present, show 5 asterisks
              value={props.form.depositLimitAmount ? '*****' : ''}
              name='depositLimitAmount'
              disabled
              classNames={`${checkInputErrorMessages(['depositLimitAmount'], props.inputErrorMessages)[0]}`}
              updateForm={props.updateForm}
              checkInputValue={() => updateDepositLimit('amount', props.form.depositLimitAmount)}
              updateState={props.updateState} />
            {pedDisabled && <EditSelectionLink topic={TOPICS.nfc_action} displayMessage={DISPLAY_MESSAGES.depositFrequency} messageId={MESSAGE_IDS.depositFrequency} keypadDigits={1} type={MessageTypes.NFC_ACTION} />}
          </div>
          <ErrorMessage id='deposit-limit-amount' message={checkInputErrorMessages(['depositLimitAmount'], props.inputErrorMessages)[1]} />
        </div>
        <CustomerMessage title='Customer Message' id='customer-message' infoButtonTooltip={customerMessageInfoButton} classNames='customer-message-title-button margin-top'>
          We've introduced a monthly net deposit limit for all customers, to help them stay in control and gamble within their means. This limit can change depending on how old they are, and is valid for the first 30 days after making their first deposit.
        </CustomerMessage>
      </div>
    </div>
  );
}
