import axios from 'axios';
import { constants } from './constants';
import handleAxiosError from './handleAxiosError';
import { responseWithBody } from '../types';
import { logger } from '../lib/logger';

export async function callAccountService(method = 'get', ssbtAccount = '') {
  try {
    const { status, data } = await axios.request({
      method: `${method}`,
      url: `${constants.get('REACT_APP_ACCOUNT_SERVICE_BASE_URL')}/v1/account/${ssbtAccount}`,
    });
    logger.log(`AccountService status: ${status}`);
    return responseWithBody(data, status);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      logger.error('Error response: ', error.response);
      logger.error('Error response status: ', error.response?.status);
      return handleAxiosError(error);
    } else {
      logger.error('Unexpected error :', error);
      return responseWithBody('unexpected error', 500);
    }
  }
}

export default callAccountService;
