import React, { Component } from 'react';
import axios from 'axios';
import './App.css';
import TopNavigation from './components/TopNavigation';
import RegistrationForm from './components/RegistrationForm';
import { FormStateType, initialAddress, initialForm } from './Form';
import { formValidationRules } from './components/RegistrationForm/formValidation';
import { addressFieldNames, fieldNames } from './components/RegistrationForm/fieldNames';
import callCardService from './utils/cardService';
import callAccountService from './utils/accountService';
import checkInputErrorMessages from './components/RegistrationForm/checkInputErrorMessages';
import callPhoneService from './utils/phoneService';
import { constants } from './utils/constants';
import {
  ApiResponse,
  ConsentsUpdate,
  CreateAccountRequest,
  Data,
  LinkOnlineAccountWithPlusAccountRequest,
  responseWithBody,
} from './types';
import createOnlineAccountService from './utils/createOnlineAccountService';
import linkOnlineAccountWIthPlus from './utils/linkOnlineAccountWithPlus';
import SubscribePinResponse from './components/SubscribePinResponse';
import Loading from './Loading';
import getStatus from './utils/getStatus';
import logRegistration from './utils/logRegistration';
import { connect } from 'react-redux';
import { changeDepositLimit, changePin, changeTermsAndConditions, changeMarketing } from './utils/store/pedSwitched';
import { changeDepositLimitDisabled, changePinDisabled, changeTermsAndConditionsDisabled, changeMarketingDisabled } from './utils/store/pedDisabled';
import callWalletService from './utils/walletService';
import createPinInUserAuthenticationService from './utils/userAuthenticationService';
import { logger } from './lib/logger';

type AppProps = {
  dispatch: any
}

type AppState = {
  success: boolean,
  form: FormStateType,
  error: boolean | string,
  inputErrorMessages: object,
  saved: boolean,
  retrievedSession: object,
  autofillPrompt: boolean,
  usernamePrompt: boolean,
  retrievedUsernames: any,
  loading: boolean,
  failed: boolean,
  failureReason: string,
  responseSubmitted: boolean,
  lastCompletedField: string,
  openBetAccount: string
}

const initialState = {
  success: false,
  form: JSON.parse(JSON.stringify(initialForm)),
  error: false,
  inputErrorMessages: {},
  saved: false,
  retrievedSession: {},
  autofillPrompt: false,
  usernamePrompt: false,
  retrievedUsernames: ['suggestion1', 'suggestion2', 'suggestion3'],
  loading: false,
  failed: false,
  failureReason: '',
  responseSubmitted: false,
  lastCompletedField: '',
  openBetAccount: ''
};

class App extends Component<AppProps, AppState> {

  state = JSON.parse(JSON.stringify(initialState));

  updateState(key: string, value: any) {
    this.setState(prevState => {
      let {
        form,
        error,
        success,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
        loading
      } = prevState;
      if (key == 'form') {
        form = value;
      } else if (key == 'error') {
        error = value;
      } else if (key == 'success') {
        success = value;
      } else if (key == 'inputErrorMessages') {
        inputErrorMessages = value;
      } else if (key == 'retrievedSession') {
        retrievedSession = value;
      } else if (key == 'autofillPrompt') {
        autofillPrompt = value;
      } else if (key == 'retrievedUsernames') {
        retrievedUsernames = value;
      } else if (key == 'usernamePrompt') {
        usernamePrompt = value;
      } else if (key == 'saved') {
        saved = value;
      } else if (key == 'loading') {
        loading = value;
      }
      return {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        usernamePrompt,
        retrievedUsernames,
        loading,
      };
    });
  }

  updateSuccess(value: boolean) {
    this.setState(prevState => {
      const {
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      return {
        success: value,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
      };
    });
  }

  updateSaved(value: boolean) {
    this.setState(prevState => {
      const {
        success,
        form,
        error,
        inputErrorMessages,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      return {
        success,
        form,
        error,
        inputErrorMessages,
        saved: value,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
      };
    });
  }

  updateForm(key: any, value: any, field?: any) {
    this.setState(prevState => {
      const {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      if (field) {
        form[field as keyof typeof form][key as keyof typeof form] = value;
      } else {
        form[key as keyof typeof form] = value;
      }
      return {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
      };
    });
    this.setState({
      lastCompletedField: key
    });
  }

  setForm(newValues: object, iterate: boolean) {
    this.setState(prevState => {
      const {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      for (const [key, value] of Object.entries(newValues)) {
        form[key] = value;
      }
      return {
        success,
        form: iterate ? form : newValues,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
      };
    });
  }

  async autofill(value: boolean) {
    if (value) {
      await this.setForm(this.state.retrievedSession, true);
      this.logRegistration('Saved Form Retrieved');
    } else {
      this.setRetrievedSession({});
    }
    this.updateAutofillPrompt(false);
    for (const [key, value] of Object.entries(this.state.retrievedSession)) {
      if (!['', '0'].includes(String(value)) && !['creationTime', 'expirationTime', 'pinNumber', 'socialMediaMarketing', 'termsAndConditions'].includes(key)) {
        if (key == 'address') {
          for (const [key, data] of Object.entries(Object(value))) {
            if (data !== '') {
              this.checkInputValue(key);
            }
          }
        } else {
          this.checkInputValue(key);
        }
      }
    }
  }

  setRetrievedSession(value: object) {
    this.setState(prevState => {
      const {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      return {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession: value,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
      };
    });
  }

  updateAutofillPrompt(value: boolean) {
    this.setState(prevState => {
      const {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      return {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt: value,
        retrievedUsernames,
        usernamePrompt,
      };
    });
  }

  updateError(value: boolean | string) {
    this.setState(prevState => {
      const {
        success,
        form,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      return {
        success,
        form,
        error: value,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
      };
    });
  }

  updateFailed(value: boolean) {
    this.setState({
      failed: value
    });
  }

  updateResponseSubmitted(value: boolean) {
    this.setState({
      responseSubmitted: value
    });
  }

  updateFailureReason(value: string) {
    this.setState({
      failureReason: value
    });
  }

  resetForm() {
    this.setState(JSON.parse(JSON.stringify(initialState)));
    this.props.dispatch(changeDepositLimit(false));
    this.props.dispatch(changePin(false));
    this.props.dispatch(changeTermsAndConditions(false));
    this.props.dispatch(changeMarketing(false));
    this.props.dispatch(changeDepositLimitDisabled(false));
    this.props.dispatch(changePinDisabled(false));
    this.props.dispatch(changeTermsAndConditionsDisabled(true));
    this.props.dispatch(changeMarketingDisabled(false));
  }

  async logRegistration(type: string) {
    const { status, data } = await getStatus();
    let ipAddress, name, shopId, shopName;
    ipAddress = name = shopId = shopName = 'unknown';
    const date = new Date();
    const { plusCardNumber, addressQuestion, onlineQuestion, customerIdQuestion, plusCardNew } = this.state.form;
    if (status == 200) {
      ({ ipAddress, name, shopId, shopName } = data.deviceDetails);
    }
    const log = `Type: ${type} | Date: ${date} | PLUS Card Number: ${plusCardNumber} | IP Address: ${ipAddress} | Terminal: ${name} | Shop ID: ${shopId} | Shop Name: ${shopName} | Online Question Response: ${onlineQuestion} | Address Question Response: ${addressQuestion} | ID Question Response: ${customerIdQuestion} | New PLUS Card: ${plusCardNew} | Last Completed Field: ${this.state.lastCompletedField} | OpenBet Account: ${this.state.openBetAccount}`;
    logRegistration(log);
  }

  async checkPlusCard() {
    const cardNumber = String(this.state.form.plusCardNumber).slice(4);
    const cardResponse = await callCardService(cardNumber);
    if (cardResponse.status == 200) {
      if (cardResponse.data.ssbtAccount == null) {
        this.updateInputErrorMessages('plusCardLinked', 'PLUS card successfully linked');
      } else {
        const { blocked, ssbtAccount } = cardResponse.data;
        if (blocked) {
          this.updateInputErrorMessages('plusCardNumber', 'PLUS card is blocked');
        } else if (ssbtAccount) {
          const accountServiceResponse = await callAccountService('get', ssbtAccount);
          const { openBetAccount } = accountServiceResponse.data;
          if (openBetAccount == null) {
            logger.log('card not linked');
            this.updateInputErrorMessages('plusCardLinked', 'PLUS card successfully linked');
          } else {
            this.updateInputErrorMessages('plusCardNumber', 'PLUS card already linked to an account. Different PLUS card required');
          }
        } else {
          this.updateInputErrorMessages('plusCardLinked', 'PLUS card successfully linked');
        }
      }
    } else {
      this.updateInputErrorMessages('plusCardNumber', 'PLUS card does not exist');
    }
  }

  async sendData(type: string) {
    try {
      const { status } = await axios.request({
        method: 'post',
        url: `${constants.get('REACT_APP_REGISTRATION_SERVICE_BASE_URL')}/omni/saveSession`,
        data: this.state.form,
      });
      logger.log('status: ' + status);
      if (status == 201) {
        this.updateError(false);
        if (type == 'submit') {
          await this.createOnlineAccountAndPlusCard();
        } else {
          initialForm['address'] = Object.assign({}, initialAddress);
          this.logRegistration('Saved Form');
          this.updateSaved(true);
          const appWrapper = document.getElementById('app-wrapper');
          appWrapper?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        logger.log(`Error message: ${error.response && error.response.status}`);
        if (error.response && error.response.status == 400) {
          (type == 'submit') && this.updateInputErrorMessages('emailAddress', 'This email address is already in use, please use another email address');
          (type == 'submit') && this.logRegistration('Error Creating Account - email already in use');
          (type == 'save') && logger.log('Populate with previous details');
        }
      } else {
        logger.error('Unexpected error: ', error);
      }
      const appWrapper = document.getElementById('app-wrapper');
      appWrapper?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.logRegistration(`Error Saving Form (Technical Issue) - ${error}`);
      this.updateError('Error saving progress, please try again');
    }
  }

  async createPlusAccount(cardNumber: string) {
    const formPhoneNumber = `+44${this.state.form.mobileNumber.charAt(0) == '0' ? this.state.form.mobileNumber.toString().substring(1) : this.state.form.mobileNumber.toString()}`;
    const pinNumber = String(this.state.form.pinNumber);
    const cardResponse = await callCardService(`${cardNumber}`);
    if (!cardResponse.data.ssbtAccount) {
      const accountServiceResponse = await callAccountService('post');
      const ssbtAccount = accountServiceResponse.data.value;

      if (ssbtAccount) {
        await callCardService('', 'post', '', cardNumber, ssbtAccount, pinNumber);
        await createPinInUserAuthenticationService(ssbtAccount, pinNumber);
        let phoneServiceResponse: ApiResponse = await callPhoneService(`phonenumber/account/${ssbtAccount}/${formPhoneNumber}`, 'put');
        if (phoneServiceResponse.status == 201) {
          this.updateForm('plusCardNew', true);
          phoneServiceResponse = await callPhoneService('smsSend', 'post', `${formPhoneNumber}`);
          return phoneServiceResponse.status == 200 ? accountServiceResponse : phoneServiceResponse;
        } else {
          return phoneServiceResponse;
        }
      } else {
        return accountServiceResponse;
      }
    } else {
      return responseWithBody({ value: cardResponse.data.ssbtAccount }, 200);
    }
  }

  async createOnlineAccountAndPlusCard() {
    const createOnlineAccountResponse: ApiResponse = await this.createOnlineAccount();

    if (createOnlineAccountResponse?.status == 201) {
      const openBetAccount = (createOnlineAccountResponse?.data.success == false) ? createOnlineAccountResponse.data.accountNo : createOnlineAccountResponse.data.data.accountNo;
      this.setState({
        openBetAccount: openBetAccount
      });
      const cardNumber = String(this.state.form.plusCardNumber).slice(4);
      const createPlusAccountResponse: ApiResponse = await this.createPlusAccount(cardNumber);
      if (createPlusAccountResponse?.status != 200) {
        this.logRegistration(`PLUS Account Creation Failed (Technical Issue) - ${JSON.stringify(createPlusAccountResponse.data)}`);
        logger.log(`Online account creation is successful but error in creating PLUS account - ${JSON.stringify(createPlusAccountResponse.data)}`);
        this.updateSuccess(true);
        this.updateFailureReason('plus');
      } else {
        const ssbtAccount = createPlusAccountResponse.data.value;
        const linkOnlineAccountWithPlusCardResponse: ApiResponse = await this.linkOnlineAccountWithPlusCard(createOnlineAccountResponse, ssbtAccount);
        if (linkOnlineAccountWithPlusCardResponse?.status != 200) {
          this.logRegistration(`Account Linking Failed (Technical Issue) - ${JSON.stringify(linkOnlineAccountWithPlusCardResponse.data)}`);
          logger.log(`Linking online account with PLUS card has failed - ${JSON.stringify(linkOnlineAccountWithPlusCardResponse.data)}`);
          this.updateSuccess(true);
          this.updateFailureReason('link');
        } else {
          const response = await this.addPaymentMethod(ssbtAccount, cardNumber, openBetAccount);
          if (response?.status !== 200) {
            this.logRegistration(`Account Linking Failed (Technical Issue) - ${JSON.stringify(linkOnlineAccountWithPlusCardResponse.data)}`);
            this.updateSuccess(true);
            this.updateFailureReason('link');
          } else {
            if (createOnlineAccountResponse?.data.success == false) {
              this.logRegistration(`Auto Verification Failed for Online Account - ${JSON.stringify(createOnlineAccountResponse.data)}`);
            } else {
              this.logRegistration(`Online Account Creation Successful - ${JSON.stringify(createOnlineAccountResponse.data)}`);
            }
            this.updateSuccess(true);
          }
        }
      }
    } else {
      logger.log(`Creating online account has failed - ${JSON.stringify(createOnlineAccountResponse.data)}`);
      if ((createOnlineAccountResponse?.status == 400) && (createOnlineAccountResponse?.data.status == 'REG_OB_ACC_EXISTS')) {
        this.logRegistration(`Online Account Creation Failed (Email Taken) - ${JSON.stringify(createOnlineAccountResponse.data)}`);
        this.updateFailed(true);
        this.updateFailureReason('email');
      } else {
        this.logRegistration(`Online Account Creation Failed (Technical Issue) - ${JSON.stringify(createOnlineAccountResponse.data)}`);
        this.updateFailed(true);
      }
    }
  }

  async createOnlineAccount() {
    const { form } = this.state;
    const data: Data = {
      title: form.title,
      username: form.username,
      firstName: form.firstName,
      lastName: form.secondName,
      dob: `${form.birthYear}-${form.birthMonth}-${form.birthDay}`,
      email: form.emailAddress,
      mobile: form.mobileNumber.toString(),
      street1: form.address.firstLine,
      street2: form.address.secondLine,
      postcode: form.postcode,
      city: form.address.town,
      promoCode: 'SHOP60',
      language: 'en',
      contactable: form.marketingPreferences == '1' ? 'true' : 'false',
      socialable: form.socialMediaMarketing == '1' ? 'true' : 'false',
      county: form.address.county,
      countryCode: 'UK',
      currencyCode: 'GBP',
      depositLimit: form.depositLimitAmount,
      depositLimitPeriod: form.depositLimitFrequency
    };

    const createAccountRequest: CreateAccountRequest = {
      data: data,
      validatorVersion: '8af98bec5191b2f21e1a61b997d54e4894092339996eb0b2e5b48fa037371f15'
    };
    return createOnlineAccountService(createAccountRequest);
  }

  async linkOnlineAccountWithPlusCard(createOnlineAccountResponse: ApiResponse, ssbtAccount: string) {
    const consents: ConsentsUpdate = {
      marketing: this.state.form.marketingPreferences == '1'
    };
    const linkRequest: LinkOnlineAccountWithPlusAccountRequest = {
      openBetAccount: (createOnlineAccountResponse?.data.success == false) ? createOnlineAccountResponse.data.accountNo : createOnlineAccountResponse.data.data.accountNo,
      consents: consents
    };
    return linkOnlineAccountWIthPlus(ssbtAccount, linkRequest);
  }

  async addPaymentMethod(ssbtAccount: string, cardNumber: string, onlineAccountNumber: string) {
    const response = await callWalletService(ssbtAccount, cardNumber, onlineAccountNumber);
    return response;
  }

  async checkAllFields() {
    document.getElementById('manual-address-label')?.click();
    this.updateState('loading', true);
    const errors = this.check();
    if (errors.length == 0 && checkInputErrorMessages(['plusCardLinked'], this.state.inputErrorMessages)[0] !== '' && checkInputErrorMessages(['usernameVerified'], this.state.inputErrorMessages)[0] !== '') {
      await this.sendData('submit');
    } else {
      if (errors.length > 0) {
        let id = errors[0];
        if (['birthDay', 'birthMonth', 'birthYear'].includes(id)) {
          id = 'dateOfBirth';
        }
        if (['firstLine', 'town', 'country'].includes(id)) {
          id = 'postcode';
        }
        document.getElementById(`${id}-label`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      } else {
        const appWrapper = document.getElementById('app-wrapper');
        appWrapper?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      this.updateSaved(false);
      this.updateError('Not all fields completed correctly');
    }
    this.updateState('loading', false);
  }

  check() {
    const errors = [];
    fieldNames.forEach(fieldName => {
      this.checkInputValue(fieldName) && errors.push(fieldName);
    });
    addressFieldNames.forEach(fieldName => {
      this.checkInputValue(fieldName) && errors.push(fieldName);
    });
    if (checkInputErrorMessages(['plusCardLinked'], this.state.inputErrorMessages)[0] !== 'input-error') {
      this.updateInputErrorMessages('plusCardNumber', 'PLUS card must be linked');
      errors.push('plusCardNumber');
    }
    if (checkInputErrorMessages(['usernameVerified'], this.state.inputErrorMessages)[0] !== 'input-error') {
      this.updateInputErrorMessages('username', 'Username must be verified');
      errors.push('username');
    }
    return errors;
  }

  async retrieveData() {
    try {
      const { status, data } = await axios.request({
        method: 'post',
        url: `${constants.get('REACT_APP_REGISTRATION_SERVICE_BASE_URL')}/omni/retrieveSession`,
        data: { emailAddress: this.state.form.emailAddress },
      });
      logger.log('status: ' + status);
      if (status == 200) {
        if (data.body) {
          this.setRetrievedSession(data.body);
          this.updateAutofillPrompt(true);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        logger.error('Error message: ', error.response && error.response.status);
      } else {
        logger.error('Unexpected error: ', error);
      }
    }
  }

  checkInputValue(key: string) {
    if (addressFieldNames.includes(key) || fieldNames.includes(key)) {
      const form: any = addressFieldNames.includes(key) ? this.state.form.address : this.state.form;
      const keyRules = key as keyof typeof formValidationRules;
      const keyForm = key as keyof typeof form;
      const rules = formValidationRules[keyRules];
      const errors = [];
      for (let index = 0; index < rules.length; index++) {
        if (rules[index].type == 'minChars') {
          form[keyForm].toString().length < rules[index].value && errors.push(index);
        } else if (rules[index].type == 'exactChars') {
          form[keyForm].toString().length !== rules[index].value && errors.push(index);
        } else if (rules[index].type == 'maxChars') {
          form[keyForm].toString().length > rules[index].value && errors.push(index);
        } else if (rules[index].type == 'regex') {
          !form[keyForm].toString().toLowerCase().match(rules[index].value.toString()) && errors.push(index);
        } else if (rules[index].type == 'selected') {
          form[keyForm].toString() == rules[index].value.toString() && errors.push(index);
        } else if (rules[index].type == 'greaterThan') {
          form[keyForm] <= rules[index].value && errors.push(index);
        } else if (rules[index].type == 'lessThan') {
          form[keyForm] >= rules[index].value && errors.push(index);
        } else if (rules[index].type == 'depositLimitInvalid') {
          const amount = parseInt(this.state.form.depositLimitAmount, 10);
          (isNaN(amount) || (amount > 20000 || amount < 10)) && errors.push(index);
        } else if (rules[index].type == 'dob') {
          const day = this.state.form.birthDay;
          const month = this.state.form.birthMonth;
          const year = this.state.form.birthYear;
          const date = new Date();
          const currentDay = date.getDate();
          const currentMonth = date.getMonth() + 1;
          const currentYear = date.getFullYear();
          if (year == (currentYear - 18)) {
            if (month == currentMonth) {
              if (day > currentDay) {
                errors.push(index);
              }
            } else if (month > currentMonth) {
              errors.push(index);
            }
          } else if (year > currentYear - 18) {
            errors.push(index);
          }
        }
      }
      errors.length > 0 ? this.updateInputErrorMessages(key, rules[errors[0]].message) : this.updateInputErrorMessages(key, false);
      return errors.length > 0;
    }
  }

  updateInputErrorMessages(key: any, value: any) {
    this.setState(prevState => {
      const {
        success,
        form,
        error,
        inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      if (value) {
        inputErrorMessages[key] = value;
      } else {
        delete inputErrorMessages[key];
      }
      return {
        success,
        form,
        error,
        inputErrorMessages: inputErrorMessages,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
      };
    });
  }

  resetInputErrorMessages() {
    this.setState(prevState => {
      const {
        success,
        form,
        error,
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt
      }: any = prevState;
      return {
        success,
        form,
        error,
        inputErrorMessages: {},
        saved,
        retrievedSession,
        autofillPrompt,
        retrievedUsernames,
        usernamePrompt,
      };
    });
  }

  render() {
    return (
      <div className='App' data-testid='app-wrapper' id='app-wrapper'>
        <TopNavigation success={this.state.success} updateSuccess={this.updateSuccess.bind(this)} form={this.state.form}
          sendData={this.sendData.bind(this)} checkInputValue={this.checkInputValue.bind(this)} logRegistration={this.logRegistration.bind(this)} resetForm={this.resetForm.bind(this)}
          inputErrorMessages={this.state.inputErrorMessages} updateForm={this.updateForm.bind(this)} saved={this.state.saved} failed={this.state.failed} updateResponseSubmitted={this.updateResponseSubmitted.bind(this)} failureReason={this.state.failureReason} />
        {this.state.loading ? <Loading /> :
          <div>
            <RegistrationForm updateInputErrorMessages={this.updateInputErrorMessages.bind(this)}
              checkPlusCard={this.checkPlusCard.bind(this)} updateState={this.updateState.bind(this)}
              retrievedUsernames={this.state.retrievedUsernames} usernamePrompt={this.state.usernamePrompt}
              autofill={this.autofill.bind(this)} autofillPrompt={this.state.autofillPrompt}
              success={this.state.success} updateSuccess={this.updateSuccess.bind(this)}
              form={this.state.form} error={this.state.error}
              inputErrorMessages={this.state.inputErrorMessages} updateForm={this.updateForm.bind(this)}
              checkInputValue={this.checkInputValue.bind(this)}
              checkAllFields={this.checkAllFields.bind(this)} saved={this.state.saved}
              retrieveData={this.retrieveData.bind(this)} failed={this.state.failed}
              failureReason={this.state.failureReason} responseSubmitted={this.state.responseSubmitted}
              openBetAccount={this.state.openBetAccount}
            />
            <SubscribePinResponse
              updateForm={this.updateForm.bind(this)}
              updateInputErrorMessages={this.updateInputErrorMessages.bind(this)}
              updateError={this.updateError.bind(this)}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect()(App);