import axios from 'axios';
import { constants } from './constants';
import { responseWithBody } from '../types';
import handleAxiosError from './handleAxiosError';
import { logger } from '../lib/logger';

export async function callPhoneService(pathParam: string, method = 'get', mobileNumber = '') {
  try {
    const { status, data  } = await axios.request({
      method: `${method}`,
      url: `${constants.get('REACT_APP_PHONE_SERVICE_BASE_URL')}/v1/${pathParam}`,
      data: {
        mobile: mobileNumber,
        templateId: 'sms',
      },
    });
    logger.log('PhoneService Status: '+status);
    return responseWithBody(data, status);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      logger.error('Error response: ', error.response);
      logger.error('Error response status: ', error.response?.status);
      return handleAxiosError(error);
    } else {
      logger.error('Unexpected error :', error);
      return responseWithBody('unexpected error', 500);
    }
  }
}
export default callPhoneService;