import React, { Component } from 'react';
import './index.css';

type UsernamePromptProps = {
  retrievedUsernames: any,
  updateForm: any,
  updateState: any,
  updateInputErrorMessages: any,
}

class UsernamePrompt extends Component<UsernamePromptProps> {

  handleClick(option: string) {
    this.props.updateForm('username', option);
    this.props.updateState('usernamePrompt', false);
    this.props.updateState('retrievedUsernames', []);
    this.props.updateInputErrorMessages('usernameVerified', 'Username verified');
    this.props.updateInputErrorMessages('username', false);
  }

  render() {
    return (
      <div className='UsernamePrompt' data-testid='username-prompt' id='username-prompt'>
        This username is already in use. Choose one of these suggested usernames or enter a different one.
        <div className='username-prompt-button-container'>
          {this.props.retrievedUsernames.map((option: string) => {
            return (
              <div key={option} className='username-prompt-buttons' onClick={this.handleClick.bind(this, option)} id={`username-prompt-button-${option}`}>{option}</div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default UsernamePrompt;
