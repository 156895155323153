import React, { Component, PropsWithChildren } from 'react';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import InfoSvg from '../../../assets/icons/info.svg';
import RedInfoSvg from '../../../assets/icons/red-info.svg';
import onClickOutside from 'react-onclickoutside';

interface InfoButtonTooltipProps {
  id: string,
  classNames?: string,
  red?: boolean,
  below?: boolean
}

interface InfoButtonTooltipState {
  showTooltip: boolean
}

class InfoButtonTooltip extends Component<PropsWithChildren<InfoButtonTooltipProps>, InfoButtonTooltipState> {

  state: InfoButtonTooltipState = {
    showTooltip: false
  };

  handleClickOutside() {
    this.onShowTooltip(false);
  }

  onShowTooltip(showTooltip: boolean) {
    this.setState(() => {
      return {
        showTooltip: showTooltip,
      };
    });
  }

  render() {
    return (
      <button
        className={`info-button-tooltip ${this.props.classNames}`}
        data-testid='button'
        onClick={() => this.onShowTooltip(true)} id={`${this.props.id}-button`}>
        <img src={this.props.red ? RedInfoSvg : InfoSvg}/>

        {this.state?.showTooltip &&
            <div className={`tooltip-content${this.props.below ? '-below' : ''}`} id={this.props.below ? 'below-tooltip' : 'tooltip'}>{this.props.children}</div>}
      </button>
    );
  }
}

export default onClickOutside(InfoButtonTooltip);
