import React, { Component } from 'react';
import { TailSpin } from 'react-loader-spinner';
import './App.css';

class Loading extends Component {
  render() {
    return (
      <div className='Loading' data-testid='loading'>
        <TailSpin 
          height='150px'
          width='150px'
          color='#2a5fc9'
        />
      </div>
    );
  }
}

export default Loading;
