import React, { Component } from 'react';
import './index.css';
import info from '../../../../assets/icons/info.svg';

type PhonePromptProps = {
  phonePrompt: any,
}

class PhonePrompt extends Component<PhonePromptProps> {
  render() {
    return (
      <div className='PhonePrompt' data-testid='phone-prompt' id='phone-prompt'>
        <img src={info} className='phone-prompt-info' id='phone-prompt-icon' />
        <div className='phone-prompt-main' id='phone-prompt-main'>
          We found a PLUS card associated to/with this phone number. Would you like to link the existing PLUS card to the account?
          <div className='phone-prompt-button-container' >
            <div className='phone-prompt-buttons phone-prompt-no' onClick={this.props.phonePrompt.bind(this, false)} id='phone-prompt-no'>No</div>
            <div className='phone-prompt-buttons phone-prompt-yes' onClick={this.props.phonePrompt.bind(this, true)} id='phone-prompt-yes'>Yes</div>
          </div>
        </div>          
      </div>  
    );
  }
}

export default PhonePrompt;
