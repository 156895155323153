import React, { Component } from 'react';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import checkSvg from '../../../assets/icons/check-blue.svg';

type ResponseSubmittedProps = {
  message: boolean | string
}

class ResponseSubmitted extends Component<ResponseSubmittedProps> {
  render() {
    return (
      <div className='registration-form-section response-submitted' data-testid='response-submitted' >
        <img src={checkSvg} />
        {this.props.message}
      </div>
    );
  }
}

export default ResponseSubmitted;
