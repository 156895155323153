export enum TOPICS {
  nfc_action = 'cmd/device/nfc/action',
  nfc_message = 'cmd/device/nfc/message',
  nfc_action_response = 'cmd/device/nfc/action/res'
}

export enum MESSAGE_IDS {
  enterPin = 'REG_ENTER_PIN',
  confirmPin = 'CONFIRM_PIN',
  invalidPin = 'INVALID_PIN',
  depositFrequency = 'DEPOSIT_FREQUENCY',
  depositLimit = 'DEPOSIT_LIMIT',
  marketingPreferences = 'REG_MARKETING_PREFERENCES',
  termsAndConditions = 'REG_TERMS_CONDITIONS',
  socialMediaMarketing = 'SOCIAL_MEDIA_MARKETING',
  nfcFailure = 'NFC_FAILURE',
  resetDevice = 'RESET_DEVICE'
}

export enum DISPLAY_MESSAGES {
  validPin = 'Valid pin',
  invalidPin = 'Invalid pin',
  enterPin = 'Enter pin',
  confirmPin = 'Confirm pin',
  depositFrequency = 'Deposit freq..',
  depositLimit = 'Deposit limit',
  marketingPreferences = 'Marketing pref..',
  termsAndConditions = 'Ts&Cs',
  socialMediaMarketing = 'Social mrkt pref..',
  invalidDepositFrequency = 'Invalid deposit\nfrequency',
  invalidDepositLimit = 'Invalid deposit\nlimit',
  invalidTermsAndConditions = 'Invalid Ts&Cs',
  invalidMarketingPreferences = 'Invalid mrkt\npreferences',
  invalidSocialMediaMarketing = 'Invalid media\nmrkt preferences',
  validDepositLimit = 'Valid deposit',
  validMarketingPreferences = 'Valid mrkt\npreferences',
  validSocialMediaMarketing = 'Valid media\nmrkt preferences',
  validTermsAndConditions = 'Valid Ts&Cs',
  defaultMessage = 'William Hill'
}

export enum MessageTypes {
  NFC_ACTION = 'NFC_ACTION',
  NFC_MESSAGE = 'NFC_MESSAGE'
}

export default TOPICS;