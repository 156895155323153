import axios from 'axios';
import { logger } from '../lib/logger';

export default async function resetPed() {
  try {
    await axios.request({
      method: 'post',
      url: 'http://smart-hal:8080/set-state',
      data: {
        data: {
          action: 'displayMessage',
          displayMessage: 'William Hill'
        },
        state: 'enabled',
        deviceId: 'nfcReader'
      }
    });       
  } catch {
    logger.log('running locally');
  }
}
