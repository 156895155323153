export const fieldNames = [
  'title',
  'firstName',
  'secondName',
  'emailAddress',
  'birthDay',
  'birthMonth',
  'birthYear',
  'dateOfBirth',
  'mobileNumber',
  'postcode',
  'username',
  'depositLimitAmount',
  'depositLimitFrequency',
  'plusCardNumber',
  'pinNumber',
  'termsAndConditions',
  'marketingPreferences',
  'termsAndConditionsPresented',
];

export const addressFieldNames = [
  'firstLine',
  'town',
  'country',
];
