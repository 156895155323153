import { ApiResponse, responseWithBody } from '../types';

const  handleAxiosError = (error: any) :ApiResponse => {

  let errorMessage:ApiResponse;

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    errorMessage = responseWithBody(error.response.data, error.response.status);
  } else if (error.request) {
    // The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
    errorMessage = responseWithBody('Bad Request, check logger to see full error request', 400);
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = responseWithBody(error.message, 500);
  }
  return errorMessage;
};
export default handleAxiosError;