import React from 'react';
import './index.css';

function Divider(props: {id?: string}) {
  return (
    <div className='Divider' data-testid='divider' id={props.id} />
  );
}

export default Divider;
