import React from 'react';
import './index.css';
import SectionHeader from '../SectionHeader';
import Divider from '../Divider';
import CheckBox from '../CheckBox';
import { FormStateType } from '../../../Form';
import CustomerMessage from '../CustomerMessage';
import InputErrorMessage from '../Input/InputErrorMessage';

function CustomerValidation(props: {title: string, subtitle?: string, id: string, info?: any, updateForm: any, inputErrorMessages: object, checkInputValue: any, form: FormStateType}) {

  return (
    <div className='customer-validation-title' data-testid='customer-validation'>
      <div id={`${props.id}-title`}>
        <SectionHeader title='What we check with customers' subtitle={<div className='customer-validation-subtitle'>All new online accounts will go through the same checks to verify the customer's identity and address.<div className='customer-validation-subtitle-gap'>Make sure the customer's address is the same as on the electoral roll. They also need to be registered at this address for at least 6 weeks.</div><div className='customer-validation-subtitle-gap'>If they have already had a William Hill online account, they can't open a new online account today. Tell them to try reactivating their account by contacting Customer Support, and recovering their old username and password via Live Chat.</div></div>} id='customer-validation' />
      </div>
      <Divider />
      <div className={`current-situation ${(props.form.onlineQuestion == 'yes' || props.form.addressQuestion == 'yes') && 'input-error'}`}>
        <div className='current-situation-label'>Pre-Verification Questions</div>
        <div><b>Q1. </b>Have you had a William Hill online account previously?</div>
        <div className='current-situation-check-boxes'>
          <CheckBox checked={props.form.onlineQuestion == 'yes'} classNames='small-margin-right' id='online-question-yes-check-box' testId='online-question-yes' setChecked={() => {
            props.updateForm('onlineQuestion', 'yes');
            props.updateForm('addressQuestion', '');
            props.updateForm('customerIdQuestion', '');
          }} />
          <div className='current-situation-yes'>Yes</div>
          <CheckBox checked={props.form.onlineQuestion == 'no'} classNames='small-margin-right' id='online-question-no-check-box' testId='online-question-no' setChecked={() => props.updateForm('onlineQuestion', 'no')} />No
        </div>
        {props.form.onlineQuestion !== '' && props.form.addressQuestion == '' &&
          <CustomerMessage title='Customer Message' id='customer-message-online-question' classNames='customer-message-white'>
            {props.form.onlineQuestion == 'yes' ?
              <div>
                Ask the customer if it's possible to reactivate their existing account. If it is possible, tell them they can contact Customer Support, and recover their old username and password via Live Chat.
                <div className='customer-validation-subtitle-gap'>Confirm that the customer can still sign up to PLUS if they do not currently have a PLUS account.</div>
              </div> : 
              <div>Ask customer question 2.</div>
            }
          </CustomerMessage>
        }
        <Divider id='pre-verification-questions-divider' />
        <div className={props.form.onlineQuestion !== 'no' ? 'customer-validation-opaque' : ''}>
          <div><b>Q2. </b>Have you changed address in the past 6 weeks?</div>
          <div className='current-situation-check-boxes'>
            <CheckBox disabled={props.form.onlineQuestion !== 'no'} classNames='small-margin-right' id='address-question-yes-check-box' testId='address-question-yes' checked={props.form.addressQuestion == 'yes'} setChecked={() => {
              props.updateForm('addressQuestion', 'yes');
              props.updateForm('customerIdQuestion', '');
            }} /><div className='current-situation-yes'>Yes</div>
            <CheckBox disabled={props.form.onlineQuestion !== 'no'} classNames='small-margin-right' id='address-question-no-check-box' testId='address-question-no' checked={props.form.addressQuestion == 'no'} setChecked={() => props.updateForm('addressQuestion', 'no')} />No
          </div>
          {props.form.addressQuestion !== '' &&
            <CustomerMessage title='Customer Message' id='customer-message-address-question' classNames='customer-message-white'>
              {props.form.addressQuestion == 'yes' ?
                <div>
                  Tell the customer that it may be harder to verify an address that they've been in for 6 weeks or less, or if it doesn't match the electoral roll. They should sign up to a William Hill account using their own device in this case.
                  <div className='customer-validation-subtitle-gap'>Confirm that the customer can still sign up to PLUS If they don't have a PLUS card right now.
                    <br />Once they've registered, they can then create and link PLUS to a new William Hill account quickly and easily through plus.williamhill.com.
                  </div>
                </div> : 
                <div>Proceed with sign-up</div>
              }
            </CustomerMessage>
          }
        </div>
        <Divider id='pre-verification-questions-divider' />
        <div className={props.form.addressQuestion !== 'no' ? 'customer-validation-opaque' : ''}>
          <div className='current-situation-label customer-id'>Customer ID</div>
          <div><i>Where possible,</i> ask customer for some ID to help ensure that their details are captured correctly</div>
          <div className='current-situation-check-boxes'>
            <CheckBox disabled={props.form.addressQuestion !== 'no'} classNames='small-margin-right' id='customer-id-dl-check-box' testId='customer-id-dl' checked={props.form.customerIdQuestion == 'dl'} setChecked={() => props.updateForm('customerIdQuestion', 'dl')} /><div className='current-situation-yes'>Driving License</div>
            <CheckBox disabled={props.form.addressQuestion !== 'no'} classNames='small-margin-right' id='customer-id-pp-check-box' testId='customer-id-pp' checked={props.form.customerIdQuestion == 'pp'} setChecked={() => props.updateForm('customerIdQuestion', 'pp')} /><div className='current-situation-yes'>Passport</div>
            <CheckBox disabled={props.form.addressQuestion !== 'no'} classNames='small-margin-right' id='customer-id-other-check-box' testId='customer-id-other' checked={props.form.customerIdQuestion == 'other'} setChecked={() => props.updateForm('customerIdQuestion', 'other')} /><div className='current-situation-yes'>Other</div>
            <CheckBox disabled={props.form.addressQuestion !== 'no'} classNames='small-margin-right' id='customer-id-no-check-box' testId='customer-id-no' checked={props.form.customerIdQuestion == 'no'} setChecked={() => props.updateForm('customerIdQuestion', 'no')} />No ID
          </div>
        </div>
      </div>
      {(props.form.onlineQuestion == 'yes' || props.form.addressQuestion == 'yes') &&
        <InputErrorMessage message="Unable to complete online account sign-up in shop unless customer responds 'No' to both pre-verification questions" id='customer-validation-error-message' />
      }
    </div>
  );
}

export default CustomerValidation;
