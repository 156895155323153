import React from 'react';
import './index.css';

function Label(props: {label: string, classNames?: string, onClick?: any, testId?: string, id?: string}) {
  return (
    <div className={`Label ${props.classNames}`} data-testid={props.testId} onClick={props.onClick} id={`${props.id}-label`}>
      {props.label}
    </div>
  );
}

export default Label;
