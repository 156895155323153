import React, { Component } from 'react';
import Divider from '../Divider';
import SectionHeader from '../SectionHeader';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import Label from '../Label';
import Input from '../Input';
import ErrorMessage from '../Input/InputErrorMessage';
import checkInputErrorMessages from '../checkInputErrorMessages';
import PostcodeLookup from './PostcodeLookup';
import { FormStateType } from '../../../Form';
import AutofillPrompt from './AutofillPrompt';

type PersonalDetailsProps = {
  updateForm?: any,
  inputErrorMessages: object,
  checkInputValue: any,
  retrieveData: any,
  form: FormStateType,
  autofillPrompt: boolean,
  autofill: any,
  queryPhoneNumber: any,
}

class PersonalDetails extends Component<PersonalDetailsProps> {

  async updateTitle(value: string) {
    await this.props.updateForm('title', value);
    this.props.checkInputValue('title');
  }

  checkInputValues(value: string) {
    this.props.checkInputValue(value);
    this.props.checkInputValue('dateOfBirth');
  }

  render() {
    return (
      <div className='PersonalDetails' data-testid='personal-details'>
        <SectionHeader title='Personal details' subtitle='Complete all fields.' id='personal-details' />
        <Divider />
        <div className='registration-form-body'>
          <Label label='Title' id='title' />
          <div className='registration-form-container'>
            <button className={`registration-form-buttons ${this.props.form.title == 'Mr' && 'registration-form-buttons-active'} ${checkInputErrorMessages(['title'], this.props.inputErrorMessages)[0]}`} onClick={this.updateTitle.bind(this, 'Mr')} data-testid='title-button-mr' id='title-button-mr'>Mr</button>
            <button className={`registration-form-buttons ${this.props.form.title == 'Mrs' && 'registration-form-buttons-active'} ${checkInputErrorMessages(['title'], this.props.inputErrorMessages)[0]}`} onClick={this.updateTitle.bind(this, 'Mrs')} data-testid='title-button-mrs' id='title-button-mrs'>Mrs</button>
            <button className={`registration-form-buttons ${this.props.form.title == 'Ms' && 'registration-form-buttons-active'} ${checkInputErrorMessages(['title'], this.props.inputErrorMessages)[0]}`} onClick={this.updateTitle.bind(this, 'Ms')} data-testid='title-button-ms' id='title-button-ms'>Ms</button>
            <button className={`registration-form-buttons ${this.props.form.title == 'Miss' && 'registration-form-buttons-active'} ${checkInputErrorMessages(['title'], this.props.inputErrorMessages)[0]}`} onClick={this.updateTitle.bind(this, 'Miss')} data-testid='title-button-miss' id='title-button-miss'>Miss</button>
          </div>
          <ErrorMessage message={checkInputErrorMessages(['title'], this.props.inputErrorMessages)[1]} id='title' />
          <Label label='First name' id='firstName' />
          <Input id='first-name-input' placeholder="Enter customer's first name" value={this.props.form.firstName} name='firstName' classNames={checkInputErrorMessages(['firstName'], this.props.inputErrorMessages)[0]} updateForm={this.props.updateForm.bind(this)} inputType='text' checkInputValue={this.props.checkInputValue.bind(this, 'firstName')} />
          <ErrorMessage message={checkInputErrorMessages(['firstName'], this.props.inputErrorMessages)[1]} id='first-name' />
          <Label label='Second name' id='secondName' />
          <Input id='second-name-input' placeholder="Enter customer's second name" value={this.props.form.secondName} name='secondName' classNames={checkInputErrorMessages(['secondName'], this.props.inputErrorMessages)[0]} updateForm={this.props.updateForm.bind(this)} inputType='text' checkInputValue={this.props.checkInputValue.bind(this, 'secondName')} />
          <ErrorMessage message={checkInputErrorMessages(['secondName'], this.props.inputErrorMessages)[1]} id='second-name' />
          <Label label='Email' id='emailAddress' />
          <Input id='email-input' placeholder="Enter customer's preferred email address" value={this.props.form.emailAddress} name='emailAddress' classNames={`${(checkInputErrorMessages(['emailAddress'], this.props.inputErrorMessages)[0])} ${(this.props.autofillPrompt && 'input-prompt')}`} updateForm={this.props.updateForm.bind(this)} inputType='email' checkInputValue={this.props.checkInputValue.bind(this, 'emailAddress')} retrieveData={this.props.retrieveData.bind(this)} />
          <ErrorMessage message={checkInputErrorMessages(['emailAddress'], this.props.inputErrorMessages)[1]} id='email' />
          {this.props.autofillPrompt && <AutofillPrompt autofill={this.props.autofill.bind(this)} id='email' />}
          <Label label='Date of birth' id='dateOfBirth' />
          <div className='personal-details-options-row'>
            <Input id='dob-day-input' placeholder='DD' classNames={`input-centre-text ${checkInputErrorMessages(['birthDay', 'dateOfBirth'], this.props.inputErrorMessages)[0]}`} value={this.props.form.birthDay !== 0 ? this.props.form.birthDay.toString() : ''} name='birthDay' updateForm={this.props.updateForm.bind(this)} inputType='text' checkInputValue={this.checkInputValues.bind(this, 'birthDay')} maxLength={2} /> 
            <Input id='dob-month-input' placeholder='MM' classNames={`input-centre-text ${checkInputErrorMessages(['birthMonth', 'dateOfBirth'], this.props.inputErrorMessages)[0]}`} value={this.props.form.birthMonth !== 0 ? this.props.form.birthMonth.toString() : ''} name='birthMonth' updateForm={this.props.updateForm.bind(this)} inputType='text' checkInputValue={this.checkInputValues.bind(this, 'birthMonth')} maxLength={2} />
            <Input id='dob-year-input' placeholder='YYYY' classNames={`input-centre-text ${checkInputErrorMessages(['birthYear', 'dateOfBirth'], this.props.inputErrorMessages)[0]}`} value={this.props.form.birthYear !== 0 ? this.props.form.birthYear.toString() : ''} name='birthYear' updateForm={this.props.updateForm.bind(this)} inputType='text' checkInputValue={this.checkInputValues.bind(this, 'birthYear')} maxLength={4} />
          </div>
          <ErrorMessage message={checkInputErrorMessages(['birthDay', 'birthMonth', 'birthYear', 'dateOfBirth'], this.props.inputErrorMessages)[1]} id='birthday' />
          <Label label='Mobile number' id='mobileNumber' />
          <div className='personal-details-options-row'>
            <div className='personal-details-country-code'>+44</div>
            <Input id='mobile-input' queryPhoneNumber={this.props.queryPhoneNumber.bind(this)} placeholder="Enter customer's mobile phone number" value={this.props.form.mobileNumber !== 0 ? this.props.form.mobileNumber.toString() : ''} name='mobileNumber' classNames={`input-flex-grow-right ${checkInputErrorMessages(['mobileNumber'], this.props.inputErrorMessages)[0]}`} updateForm={this.props.updateForm.bind(this)} inputType='number' checkInputValue={this.props.checkInputValue.bind(this, 'mobileNumber')} />
          </div>
          <ErrorMessage message={checkInputErrorMessages(['mobileNumber', 'countryCode'], this.props.inputErrorMessages)[1]} id='mobile' />
          <PostcodeLookup address={this.props.form.address} postcode={this.props.form.postcode} updateForm={this.props.updateForm} inputErrorMessages={this.props.inputErrorMessages} checkInputValue={this.props.checkInputValue} />
          <div className='personal-details-secondary-text'>We need this information to validate the customer's identity</div>
        </div>
      </div>
    );
  }
}

export default PersonalDetails;
