import React, { createContext, useEffect, useMemo, useState } from 'react';
import { IMqttContext, MqttError } from '../../types';
import { connect, MqttClient } from 'mqtt';
import { logger } from '../../lib/logger';

const useMqttProvider = (brokerUrl: string, options: any) => {
  const [connectionStatus, setStatus] = useState<string | MqttError>('Offline');
  const [client, setClient] = useState<MqttClient | null>(null);

  useEffect(() => {
    setStatus('Connecting');

    logger.log(`Attempting to connect to ${brokerUrl}`);

    const mqtt = connect(brokerUrl, options);

    mqtt.on('connect', () => {
      logger.log('Mqtt client connected');
      setStatus('Connected');
      setClient(mqtt);
    });

    mqtt.on('reconnect', () => {
      logger.log('Mqtt client reconnecting');
      setStatus('Reconnecting');
    });

    mqtt.on('error', (err: any) => {
      logger.error('Connection error:', err);
      setStatus(err.message);
    });

    mqtt.on('offline', () => {
      logger.log('Mqtt client offline');
      setStatus('Offline');
    });

    mqtt.on('end', () => {
      logger.log('Mqtt client ended');
      setStatus('Offline');
    });

    return () => {
      if (client) {
        logger.log('closing mqtt client');
        client.end(true);
        setClient(null);
      }
    };
  }, [brokerUrl, options]);

  const value: IMqttContext = useMemo(() => ({
    connectionStatus,
    client,
  }), [connectionStatus, client]);

  return value;
};

export const MqttContext = createContext<IMqttContext>({} as IMqttContext);

type MqttProviderProps = {
  children: React.ReactNode,
  brokerUrl: string,
  options: any
}

const MqttProvider: React.FC<MqttProviderProps> = ({ children, brokerUrl, options }) => (
  <MqttContext.Provider value={useMqttProvider(brokerUrl, options)}>
    {children}
  </MqttContext.Provider>
);

export default MqttProvider;
