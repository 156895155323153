import { useEffect } from 'react';

import { SubscribeMessageData } from '../types';
import { publishMQTTMessage, useMqttState, useSubscription } from '../utils/MqttUtils';
import TOPICS, { DISPLAY_MESSAGES, MESSAGE_IDS, MessageTypes } from '../MqttConstants';
import { changePinDisabled, changeDepositLimitDisabled, changeMarketingDisabled, changeTermsAndConditionsDisabled } from '../utils/store/pedDisabled';
import { changeDepositLimit, changeMarketing, changePin, changeTermsAndConditions } from '../utils/store/pedSwitched';
import { useDispatch } from 'react-redux';
import { logger } from '../lib/logger';

interface statusProps {
  updateForm: any,
  updateInputErrorMessages: any,
  updateError: any
}

let enterPin = '';
let confirmPin = '';
let depositFrequency = '';
let depositLimit = '';
let marketingPreferences = '';
let termsAndConditions = '';
let socialMediaMarketing = '';

export default function SubscribePinResponse(updateFormProps: statusProps) {
  const client = useMqttState().client;
  const { message } = useSubscription(TOPICS.nfc_action_response);
  const dispatch = useDispatch();

  const isValidDepositFrequency = (depositFrequency: string): boolean => {
    const intDepositFrequency: number = parseInt(depositFrequency);
    return intDepositFrequency >= 1 && intDepositFrequency <= 3;
  };

  const isValidDepositLimit = (depositLimit: string): boolean => {
    const intDepositLimit: number = parseInt(depositLimit);
    return intDepositLimit >= 10 && intDepositLimit <= 20000;
  };

  useEffect(() => {
    if (message) {
      const subMessage: any = message.message;
      const subscribedMessage: SubscribeMessageData = JSON.parse(subMessage);

      switch (subscribedMessage.body.requestId) {
        case MESSAGE_IDS.enterPin:
          enterPin = subscribedMessage.body.data;
          if (!enterPin || enterPin.length != 4) {
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.invalidPin, MESSAGE_IDS.invalidPin, 0, MessageTypes.NFC_MESSAGE);
            updateFormProps.updateInputErrorMessages('pinNumber', 'Invalid PIN');
            dispatch(changePin(false));
          } else {
            publishMQTTMessage(client, TOPICS.nfc_action, DISPLAY_MESSAGES.confirmPin, MESSAGE_IDS.confirmPin, 4, MessageTypes.NFC_ACTION, true);
          }
          break;

        case MESSAGE_IDS.confirmPin:
          confirmPin = subscribedMessage.body.data;
          if (enterPin == confirmPin) {
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.validPin, '', 0, MessageTypes.NFC_MESSAGE);
            updateFormProps.updateForm('pinNumber', confirmPin);
            updateFormProps.updateInputErrorMessages('pinNumber', false);
            dispatch(changePin(false));
            dispatch(changePinDisabled(true));
          } else {
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.invalidPin, '', 0, MessageTypes.NFC_MESSAGE);
            updateFormProps.updateInputErrorMessages('pinNumber', 'PIN numbers do not match');
            dispatch(changePin(false));
          }
          break;

        case MESSAGE_IDS.depositFrequency:
          depositFrequency = subscribedMessage.body.data;
          if (!depositFrequency || depositFrequency.length != 1 || !isValidDepositFrequency(depositFrequency)) {
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.invalidDepositFrequency, '', 0, MessageTypes.NFC_MESSAGE);
            updateFormProps.updateInputErrorMessages('depositLimitFrequency', 'Invalid deposit limit frequency');
            dispatch(changeDepositLimit(false));
          } else {
            let frequency = '';
            switch (depositFrequency) {
              case '1':
                frequency = 'day';
                break;
              case '2':
                frequency = 'week';
                break;
              case '3':
                frequency = 'month';
                break;
            }
            updateFormProps.updateForm('depositLimitFrequency', frequency);
            updateFormProps.updateInputErrorMessages('depositLimitFrequency', false);
            publishMQTTMessage(client, TOPICS.nfc_action, DISPLAY_MESSAGES.depositLimit, MESSAGE_IDS.depositLimit, 5, MessageTypes.NFC_ACTION);
          }
          break;

        case MESSAGE_IDS.depositLimit:
          depositLimit = subscribedMessage.body.data;
          if (!depositLimit || !isValidDepositLimit(depositLimit)) {
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.invalidDepositLimit, '', 0, MessageTypes.NFC_MESSAGE);
            updateFormProps.updateInputErrorMessages('depositLimitAmount', 'Invalid deposit limit amount');
            dispatch(changeDepositLimit(false));
          } else {
            updateFormProps.updateForm('depositLimitAmount', subscribedMessage.body.data);
            updateFormProps.updateInputErrorMessages('depositLimitAmount', false);
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.validDepositLimit, '', 0, MessageTypes.NFC_MESSAGE);
            dispatch(changeDepositLimit(false));
            dispatch(changeDepositLimitDisabled(true));
          }
          break;

        case MESSAGE_IDS.marketingPreferences:
          marketingPreferences = subscribedMessage.body.data;
          if (!marketingPreferences || marketingPreferences.length != 1 || !['1', '2'].includes(marketingPreferences)) {
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.invalidMarketingPreferences, '', 0, MessageTypes.NFC_MESSAGE);
            updateFormProps.updateInputErrorMessages('marketingPreferences', 'Invalid marketing preferences');
            dispatch(changeMarketing(false));
          } else {
            updateFormProps.updateForm('marketingPreferences', subscribedMessage.body.data);
            updateFormProps.updateInputErrorMessages('marketingPreferences', false);
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.validMarketingPreferences, '', 0, MessageTypes.NFC_MESSAGE);
            dispatch(changeMarketing(false));
            dispatch(changeMarketingDisabled(true));
          }
          break;

        case MESSAGE_IDS.socialMediaMarketing:
          socialMediaMarketing = subscribedMessage.body.data;
          if (!socialMediaMarketing || socialMediaMarketing.length != 1 || !['1', '2'].includes(socialMediaMarketing)) {
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.invalidSocialMediaMarketing, '', 0, MessageTypes.NFC_MESSAGE);
            updateFormProps.updateInputErrorMessages('socialMediaMarketing', 'Invalid social media marketing');
          } else {
            updateFormProps.updateForm('socialMediaMarketing', subscribedMessage.body.data);
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.validSocialMediaMarketing, '', 0, MessageTypes.NFC_MESSAGE);
          }
          break;

        case MESSAGE_IDS.termsAndConditions:
          termsAndConditions = subscribedMessage.body.data;
          if (!termsAndConditions || termsAndConditions.length != 1 || termsAndConditions != '1') {
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.invalidTermsAndConditions, '', 0, MessageTypes.NFC_MESSAGE);
            updateFormProps.updateInputErrorMessages('termsAndConditions', 'Invalid terms and conditions');
            dispatch(changeTermsAndConditions(false));
          } else {
            updateFormProps.updateForm('termsAndConditions', true);
            updateFormProps.updateInputErrorMessages('termsAndConditions', false);
            publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.validTermsAndConditions, '', 0, MessageTypes.NFC_MESSAGE);
            dispatch(changeTermsAndConditions(false));
            dispatch(changeTermsAndConditionsDisabled(true));
          }
          break;

        case MESSAGE_IDS.nfcFailure: {
          logger.log(`NFC FAILURE ${subscribedMessage.body.requestId}`);
          logger.log(`NFC FAILURE ${subscribedMessage.body.data}`);
          if (!subscribedMessage.body.data.includes('[Errno 110] Operation timed out')) {
            dispatch(changeTermsAndConditions(false));
            dispatch(changeDepositLimit(false));
            dispatch(changeMarketing(false));
            dispatch(changePin(false));
            updateFormProps.updateError('NFC Reader not responding. Sign-up not possible');
            const appWrapper = document.getElementById('app-wrapper');
            appWrapper?.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }
          break;
        }

        default:
          logger.warning(`Unhandled MESSAGE_ID of ${subscribedMessage.body.requestId}`);
          break;
      }
    }
  }, [message]);

  return null;
}
