import React, { PropsWithChildren, ReactNode } from 'react';
import './index.css';

interface CustomerMessagePops {
  title: string;
  id: string;
  classNames?: string;
  infoButtonTooltip?: ReactNode
}

function CustomerMessage(props: PropsWithChildren<CustomerMessagePops>) {
  return (
    <div className={`customer-message ${props.classNames}`} data-testid='customer-message' id={props.id}>
      <div className='customer-message-title' id={`${props.id}-title`}>
        <span>{props.title}</span>
        {props.infoButtonTooltip}
      </div>
      <div className='customer-message-content' id={`${props.id}-content`}>
        {props.children}
      </div>
    </div>
  );
}

export default CustomerMessage;
