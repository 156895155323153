import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface PedDisabledState {
  depositLimit: boolean,
  pin: boolean,
  termsAndConditions: boolean,
  marketing: boolean
}

const initialState: PedDisabledState = {
  depositLimit: false,
  pin: false,
  termsAndConditions: true,
  marketing: false
};

export const pedDisabledSlice = createSlice({
  name: 'pedDisabled',
  initialState,
  reducers: {
    changeDepositLimitDisabled: (state, action: PayloadAction<boolean>) => {
      state.depositLimit = action.payload;
    },
    changePinDisabled: (state, action: PayloadAction<boolean>) => {
      state.pin = action.payload;
    },
    changeTermsAndConditionsDisabled: (state, action: PayloadAction<boolean>) => {
      state.termsAndConditions = action.payload;
    },
    changeMarketingDisabled: (state, action: PayloadAction<boolean>) => {
      state.marketing = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { changeDepositLimitDisabled, changePinDisabled, changeTermsAndConditionsDisabled, changeMarketingDisabled } = pedDisabledSlice.actions;

export default pedDisabledSlice.reducer;