import React from 'react';
import Button from '../Button';
import Divider from '../Divider';
import Input from '../Input';
import Label from '../Label';
import SectionHeader from '../SectionHeader';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import ErrorMessage from '../Input/InputErrorMessage';
import checkInputErrorMessages from '../checkInputErrorMessages';
import { FormStateType } from '../../../Form';
import PhonePrompt from './PhonePrompt';
import PedToggle from '../PedToggle';
import InfoButtonTooltip from '../InfoButtonTooltip';
import EditSelectionLink from '../EditSelectionLink';
import CustomerMessage from '../CustomerMessage';
import { DISPLAY_MESSAGES, MESSAGE_IDS, MessageTypes, TOPICS } from '../../../MqttConstants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/store';
import { changePin } from '../../../utils/store/pedSwitched';

type PlusCardProps = {
  updateForm?: any,
  inputErrorMessages: object,
  checkInputValue: any,
  form: FormStateType,
  checkPlusCard: any,
  updateInputErrorMessages: any,
  showPhonePrompt: boolean,
  confirmPlusCard: any,
  updateShowPhonePrompt: any,
  updatePlusCardNumber: any
}

export default function PlusCard(props: PlusCardProps) {

  const pedDisabled = useSelector((state: RootState) => state.pedDisabled.pin);
  const pedActive = useSelector((state: RootState) => state.pedSwitched.pin);
  const dispatch = useDispatch();

  function linkButtonShouldBeDisabled() {
    return (props.form.plusCardNumber.toString().match('0109\\d{12}') == null);
  }

  function unlink() {
    props.updateInputErrorMessages('plusCardLinked', false);
  }

  async function phonePrompt(value: boolean) {
    if (value) {
      await props.confirmPlusCard();
      props.updateShowPhonePrompt(false);
      props.checkPlusCard();
    } else {
      props.updatePlusCardNumber('');
      props.updateShowPhonePrompt(false);
    }
  }

  function togglePed(value: boolean) {

    dispatch(changePin(value));

    // reset form if pin invalid
    if (!value && props.form.pinNumber === 'invalid'){
      props.form.pinNumber = '';
    }
  }

  const plusCardInfo =
    <InfoButtonTooltip id='plus-card-tooltip' classNames='left-align'>
      <p className='standard-tooltip'>
        PLUS gives the customer access to a range of fantastic offers and features when betting in store.
        In signing-up to a new William Hill account with PLUS, the customer will be eligible for our fantastic sign-up offer.
      </p>
    </InfoButtonTooltip>;

  const pedDirty = pedDisabled || pedActive;

  return (
    <div className={`registration-form-section ${pedActive && 'ped-active'}`} data-testid='plus-card' id='plus-card'>
      <SectionHeader id='plus-card' title='PLUS card' subtitle='Customer gets a William Hill PLUS card linked to this account automatically when signed up in-store.' info={plusCardInfo} />
      <PedToggle pedDisabled={pedDisabled} pedActive={pedActive} togglePed={togglePed} type={ MESSAGE_IDS.enterPin } />
      <Divider />
      <div className='registration-form-body'>
        {props.showPhonePrompt && <PhonePrompt phonePrompt={phonePrompt} />}
        <Label label='Link existing or new PLUS card to account' id='plusCardNumber' />
        <div className='registration-form-container'>
          <Input id='plus-card-input' disabled={checkInputErrorMessages(['plusCardLinked'], props.inputErrorMessages)[0] !== ''} placeholder='Scan PLUS card or enter PLUS card number' value={props.form.plusCardNumber !== 0 ? props.form.plusCardNumber.toString() : ''} name='plusCardNumber' updateForm={props.updateForm} classNames={`input-flex-grow-left ${checkInputErrorMessages(['plusCardNumber'], props.inputErrorMessages)[0]} ${(checkInputErrorMessages(['plusCardLinked'], props.inputErrorMessages)[0] !== '') && 'input-success'}`} inputType='text' checkInputValue={() => props.checkInputValue('plusCardNumber')} maxLength={16} />
          <Button id='plus-card-link' text={(checkInputErrorMessages(['plusCardLinked'], props.inputErrorMessages)[0] !== '') ? 'Unlink' : 'Link'} disabled={linkButtonShouldBeDisabled()} checkPlusCard={(checkInputErrorMessages(['plusCardLinked'], props.inputErrorMessages)[0] == '') && props.checkPlusCard} classNames={`${!linkButtonShouldBeDisabled() ? 'button-active' : ''} ${(checkInputErrorMessages(['plusCardLinked'], props.inputErrorMessages)[0] !== '') ? 'button-unlink' : ''}`} unlink={unlink} />
        </div>
        {(checkInputErrorMessages(['plusCardLinked'], props.inputErrorMessages)[0] !== '') ?
          <ErrorMessage id='plus-card-linked' success={true} message={checkInputErrorMessages(['plusCardLinked'], props.inputErrorMessages)[1]} /> :
          <ErrorMessage id='plus-card-number' message={checkInputErrorMessages(['plusCardNumber'], props.inputErrorMessages)[1]} />
        }
        <CustomerMessage title='Customer Message' id='customer-message' classNames='margin-top'>
          Use the NFC Reader device to input 4 digit PIN number.
        </CustomerMessage>
        <div className='pin-container'>
          <div className='pin-title' id='pinNumber-label'>
            Create 4-digit PIN number
            <InfoButtonTooltip id='pin-tooltip' classNames='left-align'>
              <p className='standard-tooltip'>
                <b>PIN can be updated at anytime by visiting plus.williamhill.com</b>
                <p>
                  PIN is required for the following actions:
                  <div className='tooltip-list'>1. To withdraw funds from online account in shop (customer can do this via plus.williamhill.com)</div>
                  <div className='tooltip-list'>2. To access online funds when using an SSBT</div>
                </p>
              </p>
            </InfoButtonTooltip>
          </div>
          <div className={`pin-digit-container ${((pedDirty && (props.form.pinNumber === 'invalid')) || checkInputErrorMessages(['pinNumber'], props.inputErrorMessages)[0] !== '') && 'input-error-pin'} ${pedDirty && props.form.pinNumber.length === 4 && 'input-success'}`}>
            <div className='pin-digit'>*</div>
            <div className='pin-digit'>*</div>
            <div className='pin-digit'>*</div>
            <div className='pin-digit margin-right'>*</div>
            { pedDisabled && <EditSelectionLink topic={TOPICS.nfc_action} displayMessage={DISPLAY_MESSAGES.enterPin} messageId={MESSAGE_IDS.enterPin} keypadDigits={4} type={MessageTypes.NFC_ACTION}/> }
          </div>
          {pedDirty && props.form.pinNumber === 'invalid' && <ErrorMessage id='pin-invalid' message='Pin numbers do not match' />}
          {pedDirty && props.form.pinNumber.length === 4 && <p id='pin-valid'>PIN created Successfully!</p>}
          <ErrorMessage id='pin-number' message={checkInputErrorMessages(['pinNumber'], props.inputErrorMessages)[1]} />
        </div>
      </div>
    </div>
  );
}
