import axios from 'axios';
import { constants } from './constants';
import { responseWithBody } from '../types';
import handleAxiosError from './handleAxiosError';
import { logger } from '../lib/logger';

export async function callCardService(query = '', method = 'get', header = '', cardNumber = '', ssbtAccount = '', pinNumber = '') {
  try {
    const { status, data } = await axios.request({
      method: `${method}`,
      url: `${constants.get('REACT_APP_CARD_SERVICE_BASE_URL')}/v1/cards/${query}`,
      headers: { 'ssbtId': `${header}` },
      data: {
        cardNumber: cardNumber,
        ssbtAccount: ssbtAccount,
        pin: pinNumber,
      },
    });
    logger.log('CardService: ' + status);
    return responseWithBody(data, status);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      logger.error('Error response: ', error.response);
      logger.error('Error response status: ', error.response?.status);
      return handleAxiosError(error);
    } else {
      logger.error('Unexpected error :', error);
      return responseWithBody('unexpected error', 500);
    }
  }
}
export default callCardService;