import React, { Component } from 'react';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import cross from '../../../assets/icons/cross.svg';
import critical from '../../../assets/icons/critical.svg';

type ErrorMessageProps = {
  message: boolean | string,
  failed?: boolean,
  padding?: boolean
}

class ErrorMessage extends Component<ErrorMessageProps> {
  render() {
    return (
      <div className={`${this.props.padding ? 'padding' : 'registration-form-section'} error-message`} data-testid='error-message' id='form-error-message'>
        <img src={this.props.failed ? critical : cross} className={`error-message-${this.props.failed ? 'critical' : 'cross'}`} id='form-error-icon' />
        {this.props.message}
      </div>
    );
  }
}

export default ErrorMessage;
