import React, { Component } from 'react';
import './index.css';

type ButtonProps = {
  text: string,
  classNames?: string,
  disabled?: boolean,
  checkAllFields?: any,
  enterManually?: any,
  checkPlusCard?: any,
  unlink?: any,
  checkPin?: any,
  changePin?: any,
  id: string,
  resetPed?: any
}

class Button extends Component<ButtonProps> {

  handleClick() {
    this.props.checkAllFields && this.props.checkAllFields();
    this.props.enterManually && this.props.enterManually();
    this.props.checkPlusCard && this.props.checkPlusCard();
    this.props.unlink && this.props.unlink();
    this.props.checkPin && this.props.checkPin();
    this.props.changePin && this.props.changePin();
    this.props.resetPed && this.props.resetPed();
  }

  render() {
    return (
      <button className={`Button ${this.props.classNames}`} data-testid='button' disabled={this.props.disabled} onClick={this.handleClick.bind(this)} id={`${this.props.id}-button`}>
        {this.props.text}
      </button>
    );
  }
}

export default Button;
