import axios from 'axios';
import { constants } from './constants';
import handleAxiosError from './handleAxiosError';
import { LinkOnlineAccountWithPlusAccountRequest, responseWithBody } from '../types';
import { logger } from '../lib/logger';

export default async function linkOnlineAccountWithPlus(accountId: string, requestBody: LinkOnlineAccountWithPlusAccountRequest) {
  try {
    const { status, data } = await axios.request({
      method: 'put',
      url: `${constants.get('REACT_APP_REGISTRATION_SERVICE_BASE_URL')}/omni/linkAccountWithPlus/${accountId}`,
      data: requestBody,
    });
    logger.log('linkOnlineAccountWithPlus Status: ' + status);
    return responseWithBody(data, status);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      logger.error('Error response: ', error.response);
      logger.error('Error response status: ', error.response?.status);
      return handleAxiosError(error);
    } else {
      logger.error('Enexpected error :', error);
      return responseWithBody('unexpected error', 500);
    }
  }
}
