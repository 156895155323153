import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface PedSwitchedState {
  depositLimit: boolean,
  pin: boolean,
  termsAndConditions: boolean,
  marketing: boolean
}

const initialState: PedSwitchedState = {
  depositLimit: false,
  pin: false,
  termsAndConditions: false,
  marketing: false
};

export const pedSwitchedSlice = createSlice({
  name: 'pedSwitched',
  initialState,
  reducers: {
    changeDepositLimit: (state, action: PayloadAction<boolean>) => {
      setAllToFalse(state);
      state.depositLimit = action.payload;
    },
    changePin: (state, action: PayloadAction<boolean>) => {
      setAllToFalse(state);
      state.pin = action.payload;
    },
    changeTermsAndConditions: (state, action: PayloadAction<boolean>) => {
      setAllToFalse(state);
      state.termsAndConditions = action.payload;
    },
    changeMarketing: (state, action: PayloadAction<boolean>) => {
      setAllToFalse(state);
      state.marketing = action.payload;
    }
  }
});

function setAllToFalse(obj: { [key: string]: boolean }){
  Object.keys(obj).forEach(key => {
    obj[key] = false;
  });
}

// Action creators are generated for each case reducer function
export const { changeDepositLimit, changePin, changeTermsAndConditions, changeMarketing } = pedSwitchedSlice.actions;

export default pedSwitchedSlice.reducer;