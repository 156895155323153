import React from 'react';
import './index.css';
import '../../../assets/styles/RegistrationFormSections.css';
import Label from '../Label';
import { publishMQTTMessage, useMqttState } from '../../../utils/MqttUtils';
import TOPICS, { DISPLAY_MESSAGES, MESSAGE_IDS, MessageTypes } from '../../../MqttConstants';
import { useDispatch } from 'react-redux';
import { changeDepositLimit, changeMarketing, changePin } from '../../../utils/store/pedSwitched';
import { changeDepositLimitDisabled, changeMarketingDisabled, changePinDisabled } from '../../../utils/store/pedDisabled';
import resetPed from '../../../utils/resetPed';

export default function EditSelectionLink (props: {topic: TOPICS, displayMessage: DISPLAY_MESSAGES, messageId: MESSAGE_IDS, keypadDigits: number, type: MessageTypes}) {
  const { client } = useMqttState();
  const dispatch = useDispatch();

  return (
    <Label onClick={async () => {
      let changePed: any;
      let changePedDisabled: any;
      switch (props.messageId) {
        case MESSAGE_IDS.enterPin:
          changePed = changePin;
          changePedDisabled = changePinDisabled;
          break;
        case MESSAGE_IDS.depositFrequency:
          changePed = changeDepositLimit;
          changePedDisabled = changeDepositLimitDisabled;
          break;
        case MESSAGE_IDS.marketingPreferences:
          changePed = changeMarketing;
          changePedDisabled = changeMarketingDisabled;
          break;
      }
      if (changePed && changePedDisabled) {
        dispatch(changePed(true));
        dispatch(changePedDisabled(false));
      }
      await resetPed();
      publishMQTTMessage(client, props.topic, props.displayMessage, props.messageId, props.keypadDigits, props.type);
    }} label='Edit' classNames='edit-selection-link'></Label>
  );

}
