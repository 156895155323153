import React, { Component } from 'react';
import './index.css';
import SectionHeader from '../SectionHeader';
import { FormStateType } from '../../../Form';

type SavedProps = {
  form: FormStateType,
}

class Saved extends Component<SavedProps> {
  render() {
    return (
      <div className='Saved' data-testid='saved'>
        <div className='saved-top'>
          <div className='saved-name'>
            <b>Quit and save success</b> for {this.props.form.firstName} {this.props.form.secondName}.
          </div>
          <div className='saved-email'>
            Email: {this.props.form.emailAddress}
          </div>
        </div>
        <div className='saved-main'>
          <SectionHeader title='Next steps' id='saved-header' />
          <div className='saved-message'>
            <p>Your customer has saved their progress. They can complete their registration in any William Hill shop within the next 7 days.</p>
            <p>All they need is the email address that they used to start the registration process.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Saved;
