import React from 'react';
import Switch from 'react-switch';
import TOPICS, { DISPLAY_MESSAGES, MessageTypes, MESSAGE_IDS } from '../../../MqttConstants';
import { publishMQTTMessage, useMqttState } from '../../../utils/MqttUtils';
import './index.css';
import { BasePedComponentState } from '../../../Form';
import resetPed from '../../../utils/resetPed';

interface PedProps extends BasePedComponentState {
  togglePed: any,
  pedActive: boolean,
  type: string,
  updateDepositLimit?: any,
  checkInputValue?: any
}

export default function PedToggle(props: PedProps) {
  const client = useMqttState().client;

  let displayMessage: string, id: string, digits: number, mask = false;
  switch (props.type) {
    case MESSAGE_IDS.depositFrequency:
      displayMessage = DISPLAY_MESSAGES.depositFrequency;
      id = MESSAGE_IDS.depositFrequency;
      digits = 1;
      break;
    case MESSAGE_IDS.enterPin:
      displayMessage = DISPLAY_MESSAGES.enterPin;
      id = MESSAGE_IDS.enterPin;
      digits = 4;
      mask = true;
      break;
    case MESSAGE_IDS.termsAndConditions:
      displayMessage = DISPLAY_MESSAGES.termsAndConditions;
      id = MESSAGE_IDS.termsAndConditions;
      digits = 1;
      break;
    case MESSAGE_IDS.marketingPreferences:
      displayMessage = DISPLAY_MESSAGES.marketingPreferences;
      id = MESSAGE_IDS.marketingPreferences;
      digits = 1;
      break;
    case MESSAGE_IDS.socialMediaMarketing:
      displayMessage = DISPLAY_MESSAGES.socialMediaMarketing;
      id = MESSAGE_IDS.socialMediaMarketing;
      digits = 1;
      break;
  }
  
  return (
    <div className='PedToggle' data-testid='ped-toggle'>
      <b className={`ped-toggle-label ${props.pedDisabled && 'muted'}`}>NFC Reader</b>
      <Switch onChange={async () => {
        props.togglePed(!props.pedActive);
        await resetPed();
        !props.pedActive ? publishMQTTMessage(client, TOPICS.nfc_action, displayMessage, id, digits, MessageTypes.NFC_ACTION, mask) : publishMQTTMessage(client, TOPICS.nfc_message, DISPLAY_MESSAGES.defaultMessage, '', 0, MessageTypes.NFC_MESSAGE);
      }} checked={props.pedActive} width={108} height={55} handleDiameter={47} offColor='#EAEAEA' onColor='#08D228' borderRadius={100} disabled={props.pedDisabled} uncheckedIcon={<div className='ped-toggle-off'>OFF</div>} checkedIcon={<div className='ped-toggle-on'>ON</div>} />
    </div>
  );
}
